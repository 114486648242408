import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HTTPApi } from './httpapi.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HTTPApi, private https: HttpClient) { }
  base_URL = environment.api_baseurl;
  regularData = new BehaviorSubject([]);
  hwmData = new BehaviorSubject([]);
  nonMember = new BehaviorSubject([]);
  cooData = new BehaviorSubject([]);
  docData = new BehaviorSubject([]);
  visaData = new BehaviorSubject([]);
  facilityData = new BehaviorSubject([]);
  renewData = new BehaviorSubject([]);
  issueData = new BehaviorSubject([]);
  productData = new BehaviorSubject([]);
  callMemberData = new BehaviorSubject([]);
  authModalState = new BehaviorSubject(false);
  // **********************************************Authentication API's *****************************************
  // user register api
  register(data: any): Promise<any> {
    return this.http.post('register', data).toPromise();
  }
  // user login api
  login(data: any): Observable<any> {
    return this.http.post('login', data);
  }
  // logout user api
  logoutUser(): Observable<any> {
    return this.http.post('logout');
  }

  // refresh token api
  refreshToken(): Observable<any> {
    return this.http.get('refresh');
  }

  //forgot password
  forgotPassword(data): Promise<any> {
    return this.http.post('forgot-password', data).toPromise();
  }

  // *******************************************************Services API's***************************************
  // ___________________________________________________________DOCUMENT ATTESTATION_________________________________________________________________

  // add document attestation service form api
  addDocumentAttestation(formData: any): Observable<any> {
    return this.http.post('addDocument', formData);
  }
  // update document attestation service form api
  updateDocumentAttestation(id: any, formData: any): Observable<any> {
    let endpoint = 'updateDocument/' + id;
    console.log(id);
    return this.http.post(endpoint, formData);
  }
  // get document attestation list
  getDocumentAttestationList(): Observable<any> {
    return this.http.get('getDocument');
  }

  // get document attestation list
  getDocumentAttestationById(id: any): Observable<any> {
    let endpoint = 'docs_by_id/' + id;
    return this.http.get(endpoint);
  }
  // verify document attestation
  verifyDocumentAttestation(id?: any, data?: any): Observable<any> {
    return this.http.post('verifyingDoc/' + id, data);
  }
  // pay for document attestation
  payDocumentAttestationFee(
    order_id?: any,
    id?: any,
    data?: any
  ): Observable<any> {
    return this.http.post(`payDoc/${order_id}/${id}`, data);
  }
  documentMarkasPaid(id: any, data: any) {
    return this.http.post(`mark-doc-as-paid/${id}`, data);
  }
  // ________________________________________________________________CERTIFICATE OF ORIGIN __________________________________________________
  // add certificate of origin service
  addCertificateOfOrigin(data: any): Observable<any> {
    return this.http.post('addCoo', data);
  }
  //update COO user
  updateCOOByUser(id: any, data: any): Promise<any> {
    return this.http.post(`coo/update/${id}`, data).toPromise();
  }
  // get certificate of origin member list
  getCertificateofOriginList(): Observable<any> {
    return this.http.get('getCoo');
  }
  // verify certificate of origin member
  verifycertificate(id: any, data: any): Observable<any> {
    return this.http.post('verifyingCoo/' + id, data);
  }
  // payment api for certificate of origin
  paymentCertificateOrigin(
    id?: any,
    data?: any
  ): Observable<any> {
    // as discussed with rishabh, we don't need to send order_id in this paritcular API
    return this.http.post(`payCoo/${id}`, data);
  }
  cooMarkasPaid(id: any, data: any) {
    return this.http.post(`mark-coo-as-paid/${id}`, data);
  }
  // **********************************************************call members API's ************************************************************
  // get member list of contact api
  getMemberList(): Observable<any> {
    return this.http.get('call-member');
  }

  notifiyMemberFunction(data?: any): Observable<any> {
    return this.http.post('call-member/notify-member', data);
  }
  // filter list finction
  getAllFilters(name: any, id: any): Observable<any> {
    return this.http.get('filter/' + name + '/' + id);
  }
  // side filter list data
  getSideFilterCategory(): Observable<any> {
    return this.http.get('call-member/filter-list');
  }

  // create new filter
  createNewFilter(data: any): Observable<any> {
    return this.http.post('call-member/filter', data);
  }

  getCallStatus(): Observable<any> {
    return this.http.get('log-status');
  }

  // *********************************************************Members Call Logs API's******************************************************
  getAllLogs(id?: any): Observable<any> {
    return this.http.get('call-member/logs/' + id);
  }
  addNewCallLogApi(data: any): Observable<any> {
    return this.http.post('call-member/logs', data);
  }
  editCallLog(id?: any, data?: any): Observable<any> {
    return this.http.put('call-member/logs/' + id, data);
  }

  // *****************************************************************All Members API's******************************************************
  // member turnover dropdown api
  getTurnoverData(): Observable<any> {
    return this.http.get('turnover');
  }
  // member nationality dropdown values
  getNationalityDropdownValue(): Observable<any> {
    let dropdownValue: any = {
      data: [{ value: 'Indian' }, { value: 'NRI' }, { value: 'Non Indian' }],
    };
    return dropdownValue;
  }
  // member payment modes dropdown values
  getpaymentModesDropdownValue(): Observable<any> {
    let dropdownValue: any = {
      data: [
        { value: 'Cheque' },
        { value: 'Cash' },
        { value: 'DD' },
        { value: 'NEFT/RTGS' },
      ],
    };
    return dropdownValue;
  }
  // member Gst Type dropdown values
  getGstTypesDropdownValue(): Observable<any> {
    let dropdownValue: any = {
      data: [
        { value: 'Regular' },
        { value: 'Registered' },
        { value: 'Composition' },
      ],
    };
    return dropdownValue;
  }
  // member payment modes dropdown values
  getExportTypesDropdownValue(): Observable<any> {
    let dropdownValue: any = {
      data: [
        { value: 'Manufacturer/Trader' },
        { value: 'Nature of Export Goods' },
      ],
    };
    return dropdownValue;
  }
  // member payment modes dropdown values
  getPartyTypesDropdownValue(): Observable<any> {
    let dropdownValue: any = {
      data: [
        { value: 'Not Applicable' },
        { value: 'Deemed Export' },
        { value: 'Embassy/UN Body' },
        { value: 'SEZ' },
      ],
    };
    return dropdownValue;
  }
  // member classfication dropdown api
  getMemberClassfication(id?: any): Observable<any> {
    return this.http.get(
      'member-classification/' + '?nature_of_business=' + id
    );
  }
  // member category dropdown api
  getMemberCategory(): Observable<any> {
    return this.http.get('member-category');
  }
  // regular member api
  regularMemberRegister(data: any): Observable<any> {
    // debugger;
    return this.http.post('regular-members', data);

  }
  // get regular member list
  getregularMemberList(): Observable<any> {
    return this.http.get('regular-members');
  }
  getregularMember(): Promise<any> {
    return this.http.get('regular-members').toPromise();
  }
  // approve regular member api
  approveRegularMember(id?: any, data?: any) {
    console.log(id);
    return this.http.post(`regular-members/approval/${id}`, data);
  }
  // approve regular member api
  approveRegularMemberByComittee(id?: any, data?: any) {
    console.log(id);
    return this.http.post(`comitteeDecision/${id}`, data);
  }

  deleteRegularMemberByComittee(id?: any, data?: any) {
    console.log(id);
    return this.http.post(`members/delete/${id}`, data);
  }
  // get data for verification
  getDataVerification(id: any) {
    
    return this.http.get(`getMemberForVerification/${id}`);

  }
  // get state with json

  getStateWithJson(url: any): Observable<any> {
    return this.https.get<any>(url);
  }
  // ********************************************Visa Service API's ******************************************************

  // post Service Details

  postVisaServiceDetails(data: any): Observable<any> {
    return this.http.post('addVisaRecommendation', data);
  }

  // Visa Payment

  postVisaPayment(token: any, id: any): Observable<any> {
    return this.http.post(`payVisa/${token}/${id}`);
  }

  // Upload Documents

  postDocuments(oredrId: string, id: number, modal: any): Observable<any> {
    return this.http.post(`uploadVisaDocs/${oredrId}/${id}`, modal);
  }

  // Get All data for visa  approval

  getVisaApprovalData(): Observable<any> {
    return this.http.get('getVisaRecommendation');
  }

  // Approve or Reject visa request

  visaConfirmation(id: any, modal: any): Observable<any> {
    // debugger;
    return this.http.post(`approveRejectVisa/${id}`, modal);
  }
  visaMarkasPaid(id: any, data: any) {
    return this.http.post(`mark-visa-as-paid/${id}`, data);
  }
  // get embassy list
  getEmbassy(endpoint: string) {
    return this.http.get(`embassies/${endpoint}`);
  }
  //HWM membership registration
  hwmRegistration(formData: any): Observable<any> {
    return this.http.post('hwm', formData);
  }
  // hwm draft get api
  getHWMDraftData() {
    return this.http.get('getDraftByUserId');
  }
  // get hwm member list
  getHwmMemberList(): Observable<any> {
    return this.http.get('hwm');
  }
  getHwmMember(): Promise<any> {
    return this.http.get('hwm').toPromise();
  }
  // approve and reject hwm member
  hwmMemberApproval(id?: any, data?: any): Observable<any> {
    return this.http.post(`hwm/approval/${id}`, data);
  }
  // hwm waste category get api
  getWasteSchedule() {
    return this.http.get(`hwm/schedules`)
  }
  // verification process for staff 
  verifyHwmProcess(id: any, data) {
    return this.http.post(`hwm/hwmVerification/${id}`, data)
  }
  // update status after form submission 
  updateFormStatus(id: any, status: any) {
    return this.http.get(`member-data/update-status/${id}/${status}`)
  }
  // hwm mark as paid function
  HwmMarkAsPaid(id?: any, data?: any) {
    return this.http.post(`hwm/mark-as-paid/${id}`, data)
  }
  // get RO ofiice details
  getRODetails() {
    return this.http.get(`get/regional/offices`)
  }
  // approve and reject hwm member
  multipleAddressForm(data: any): Observable<any> {
    return this.http.post(`hwm/add_details`, data);
  }
  //registration for non member exporter
  registrationForNonMember(formData: any): Observable<any> {
    return this.http.post('non-member-exporter', formData);
  }
  // get non member exporter list
  getNonMemberList(): Observable<any> {
    return this.http.get('non-member-exporter');
  }
  getNonMember(): Promise<any> {
    return this.http.get('non-member-exporter').toPromise();
  }
  //approve and reject api for non member exporter
  nonMemberApproval(id?: any, data?: any): Observable<any> {
    return this.http.post('non-member-exporter/approval/' + id, data);
  }
  // get non member by ID
  getNonMemberById(id): Observable<any> {
    return this.http.get(`get_non_member_by_id/${id}`);
  }
  // ********************************************Membership Reneal API's ******************************************************//

  getRenealList(): Observable<any> {
    return this.http.get('renewal-queue');
  }

  queueApproval(modal: any): Observable<any> {
    return this.http.post('queue-approval', modal);
  }

  renewalPayment(
    id: any,
    modal: any
  ): Observable<any> {
    return this.http.post(`payment/${id}`, modal);
  }
  // renew api on dashboard
  renew(order_id: any, member_id: any, membership_id: any, modal): Observable<any> {
    return this.http.post(`payment/${order_id}/${member_id}/${membership_id}`, modal);
  }

  renewPayment(modal: any, memberId: number): Observable<any> {
    return this.http.post(`renew_member/${memberId}`, modal);
  }

  markasPaid(modal: any) {
    return this.http.post(`mark-payment-received`, modal);
  }
  memberDashboard(): Observable<any> {
    return this.http.get('renew-dashboard');
  }

  guestBillingDetails(data?: any) {
    return this.http.post(`guest-details`, data)
  }
  getguestBillingDetails() {
    return this.http.get(`guest-details`)
  }
  // **************************************************************get Company name******************************************************
  getCompany() {
    return this.http.get('companyName');
  }
  // **************************************************************User Creation ******************************************************
  // Get roll

  getRole(): Observable<any> {
    return this.http.get('userpermissions/roles');
  }

  // Get all users

  getAllUsers(): Observable<any> {
    return this.http.get('userpermissions/users-with-role');
  }

  // Edit user details

  editUserDetails(id: number, modal: any): Observable<any> {
    return this.http.post(`userpermissions/updateStaff/${id}`, modal);
  }

  // Get user by id

  getUserById(id: number): Observable<any> {
    return this.http.get(`userpermissions/getStaffById/${id}`);
  }

  // Delete User

  deleteUser(id: number): Observable<any> {
    return this.http.delete(`userpermissions/delete/${id}`);
  }

  // **************************************************************approved member******************************************************
  // Get member
  getMember(): Observable<any> {
    return this.http.get('getMemberData');
  }

  generateOTP(modal: any): Observable<any> {
    return this.http.post('getOtp', modal);
  }
  getMemberDataList(): Observable<any> {
    return this.http.get('get_member_data_list');
  }

  updateMemberInfo(modal: any): Observable<any> {
    return this.http.post('memberDataUpdate', modal);
  }

  getOrderId(modal): Observable<any> {
    return this.http.post('generate-order', modal);
  }

  memberDataApproval(modal: any): Observable<any> {
    return this.http.post('update_member_data_status', modal);
  }

  OTPVerification(modal: any): Observable<any> {
    return this.http.post('verifyOtp', modal);
  }
  changePasswordApi(modal: any): Observable<any> {
    return this.http.post('change-password', modal);
  }
  // exportExcel(data: any, name?: string, filterBy?: any) {
  //   let headerOfFile = '';
  //   import('xlsx').then((xlsx) => {
  //     const currentDate = new Date().toLocaleDateString('en-GB');
  //     let header = '';
      
  //     if (name) {
  //       headerOfFile = name.replace(/-/g, '  ');

  //       header += `  ${headerOfFile}  `;
  //     }
  //     if (filterBy) {
  //       let filter = filterBy.replace(/_/g, ' ');
  //       header += `   filterBy: ${filter}`;
  //     }
  //     header += `   Date: ${currentDate}`;
  
  //     const worksheetData = [{ '': header }];
  //     const worksheet = xlsx.utils.json_to_sheet(worksheetData);
  
  //     // Merge cells in the header row
      
  
  //     // Add data starting from cell A2
  //     worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 50 } }];
       
  //     // Add data starting from cell A4
  //     xlsx.utils.sheet_add_json(worksheet, data, { origin: 'A4' });
  
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  //     const excelBuffer: any = xlsx.write(workbook, {
  //       bookType: 'xlsx',
  //       type: 'array',
  //     });
  
  //     if (name) {
  //       this.saveAsExcelFile(excelBuffer, name);
  //     } else {
  //       this.saveAsExcelFile(excelBuffer, 'data');
  //     }
  //   });
  // }
  

//   exportExcel(data: any, name?: string, filterBy?: any, dateRange?: any) {
//     let headerOfFile = '';
//     import('xlsx').then((xlsx) => {
//         const currentDateTime = new Date().toLocaleString('en-GB'); // Get current date and time
//         let header = '';

//         if (name) {
//             headerOfFile = name.replace(/-/g, '  ');
//             header += `  ${headerOfFile}  `;
//         }
//         if (filterBy) {
//             let filter = filterBy.replace(/_/g, ' ');
//             header += `   Filtered By: ${filter}`;
//         }
//         header += `   Date & Time: ${currentDateTime}`;

//         const worksheetData = [{ '': header }];
//         const worksheet = xlsx.utils.json_to_sheet(worksheetData);

//         // Styling for the header row
//         const headerStyle = {
//             font: { bold: true, color: { rgb: 'FF000000' } }, // bold font and black color
//             alignment: { horizontal: 'center', vertical: 'center' }, // center alignment
//             fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: 'FFD9EAD3' } }, // light green background color
//             border: { top: { style: 'thin', color: { rgb: 'FF000000' } }, bottom: { style: 'thin', color: { rgb: 'FF000000' } } } // thin black border
//         };

//         // Apply header styling
//         worksheet['A1'].s = headerStyle;

//         // Merge cells in the header row
//         worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 50 } }];

//         // Add data starting from cell A2
//         xlsx.utils.sheet_add_json(worksheet, data, { origin: 'A4' });

//         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
//         const excelBuffer: any = xlsx.write(workbook, {
//             bookType: 'xlsx',
//             type: 'array',
//         });

//         if (name) {
//             this.saveAsExcelFile(excelBuffer, name);
//         } else {
//             this.saveAsExcelFile(excelBuffer, 'data');
//         }
//     });
// }

  
// export excel new function 
exportExcel(data: any, name?: string, filterBy?: any, ) {
    let headerOfFile = '';
    import('xlsx').then((xlsx) => {
        const currentDateTime = new Date().toLocaleString('en-GB'); // Get current date and time
        let header = '';

        if (name) {
            headerOfFile = name.replace(/-/g, '  ');
            header += `  ${headerOfFile}  `;
        }
        if (filterBy && filterBy.length === 2) {
          const startDate = typeof filterBy[0] === 'string' ? filterBy[0] : filterBy[0].toLocaleDateString('en-GB');
          const endDate = typeof filterBy[1] === 'string' ? filterBy[1] : filterBy[1].toLocaleDateString('en-GB');
          header += `   From: ${startDate} - To:${endDate}`;
      }
      
        else if (filterBy) {
           if(name == 'Eligible Voter List Reports'){
            header += `   Data of : ${filterBy}`;

           }
           else{
            let filter = filterBy.replace(/_/g, ' ');
            header += `  ${filter}`;

           }
            

        }
        header += `   Date & Time: ${currentDateTime}`;

        const worksheetData = [{ '': header }];
        const worksheet = xlsx.utils.json_to_sheet(worksheetData);
        
        // Styling for the header row
        const headerStyle = {
            font: { bold: true, color: { rgb: 'FF000000' } }, // bold font and black color
            alignment: { horizontal: 'center', vertical: 'center' }, // center alignment
            fill: { type: 'pattern', pattern: 'solid', fgColor: { rgb: 'FFD9EAD3' } }, // light green background color
            border: { top: { style: 'thin', color: { rgb: 'FF000000' } }, bottom: { style: 'thin', color: { rgb: 'FF000000' } } } // thin black border
        };

        // Apply header styling
        worksheet['A1'].s = headerStyle;

        // Merge cells in the header row
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 50 } }];

        // Add data starting from cell A2
        xlsx.utils.sheet_add_json(worksheet, data, { origin: 'A4' });

        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        if (name) {
            this.saveAsExcelFile(excelBuffer, name);
        } else {
            this.saveAsExcelFile(excelBuffer, 'data');
        }
    });
}





  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    // import("file-saver").then(FileSaver => {
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
    // })
  }
  


  // exportPdf(data: any, column: any, name?: any , filteredBy?:any) {
  //   // console.log(data);
  //   const doc = new jsPDF({
  //     orientation: 'landscape',
  //   });
  //   const currentDate = new Date().toLocaleDateString('en-GB'); 
  //   const dateWidth = doc.getStringUnitWidth(`Date: ${currentDate}`) * doc.internal.scaleFactor; 
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const dateX = pageWidth - dateWidth - 40; 
  //   const fontSize = 13;
  //   const filteredByX = pageWidth - dateWidth - 100; 
  //   const header = pageWidth - dateWidth - 260; 

  //   doc.setFontSize(fontSize);
  //   doc.setTextColor(41, 128, 186);
  //   doc.text(`Date: ${currentDate}`, dateX, 10);
  //   if(filteredBy){
  //     let filter = filteredBy.replace(/_/g, ' ');
  //     doc.text(`FilteredBy : ${filter}` ,filteredByX, 10)
  //   }

  //   if(name){
  //     let newName = name.replace(/-/g, ' ');
  //           doc.text(` ${newName}` ,header, 10)
  //     doc.setTextColor(41, 128, 186);

  //   }
  //    doc['autoTable'](column, data);
    
  //   if (name) {
  //     doc.save(name);
  //   } else doc.save('report.pdf');
  // }


  exportPdfformemberStatus(data1: any, column1: any, data2: any, column2: any,data3 :any , column3:any, name: any,year?:any) {
    const doc = new jsPDF({
        orientation: 'landscape'
    });

    const currentDate = new Date().toLocaleDateString('en-GB');
    const dateWidth = doc.getStringUnitWidth(`Date: ${currentDate}`) * doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.getWidth();
    const dateX = pageWidth - dateWidth - 40;
    const fontSize = 13;
    const filteredByX = pageWidth - dateWidth - 100;
    const header = pageWidth - dateWidth - 260;

    doc.setFontSize(fontSize);
    doc.setTextColor(41, 128, 186);
    doc.text(`Date: ${currentDate}`, dateX, 10);

    if(year){
      //  let filter = filteredBy.replace(/_/g, ' ');
       doc.text(`Data of : ${year}` ,filteredByX, 10)
     }
    if (name) {
        let newName = name.replace(/-/g, ' ');
        doc.text(` ${newName}`, header, 10);
        doc.setTextColor(41, 128, 186);
    }

    if(data1){
      // doc.text("MEMBERSHIP STATUS", header, 10);
           doc['autoTable'](column1, data1);

    }
    if(data2){
      // doc.text("FINANCIAL UPDATES", header, 10);
      if (data1) {
        doc.addPage(); // Add a new page before drawing the next table
    }
      const header = function (data) {
        doc.setFontSize(12);
        doc.setTextColor(41, 128, 186); // Blue color
        doc.text('FINANCIAL UPDATES', data.settings.margin.left, 9); // Adjust title and position as needed
    };

    doc['autoTable'](column2, data2, {
        startY: 10, // Adjust startY to make space for the header
        addPageContent: header,
    });

      // doc['autoTable'](column2, data2);

    }
    if(data3){
      // doc.text("MEMBERSHIP RENEWAL STATUS" , 10 , 10);
      if (data2) {
        doc.addPage(); // Add a new page before drawing the next table
    }
      const header = function (data) {
        doc.setFontSize(12);
        doc.setTextColor(41, 128, 186); // Blue color
        doc.text('MEMBERSHIP RENEWAL STATUS', data.settings.margin.left, 9); // Adjust title and position as needed
    };

    doc['autoTable'](column3, data3, {
        startY: 10, // Adjust startY to make space for the header
        addPageContent: header,
    });

      // doc['autoTable'](column3, data3);

    }

    if (name) {
        doc.save(name);
    } else {
        doc.save('report.pdf');
    }
}
  exportPdfforApprovedMember(data1: any, column1: any, data2: any, column2: any, name: any , filteredBy?:any) {
    const doc = new jsPDF({
        orientation: 'landscape'
    });

    const currentDate = new Date().toLocaleDateString('en-GB');
    const dateWidth = doc.getStringUnitWidth(`Date: ${currentDate}`) * doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.getWidth();
    const dateX = pageWidth - dateWidth - 40;
    const fontSize = 13;
    let filteredByX = pageWidth - dateWidth - 100;
    const header = pageWidth - dateWidth - 260;

    doc.setFontSize(fontSize);
    doc.setTextColor(41, 128, 186);
    doc.text(`Date: ${currentDate}`, dateX, 10);


    if (filteredBy && filteredBy.length === 2) {
      const startDate = typeof filteredBy[0] === 'string' ? filteredBy[0] : filteredBy[0].toLocaleDateString('en-GB');
      const endDate = typeof filteredBy[1] === 'string' ? filteredBy[1] : filteredBy[1].toLocaleDateString('en-GB');
      filteredByX = pageWidth - dateWidth - 130;
      doc.text(`From : ${startDate} To: ${endDate}` ,filteredByX, 10)

  }
  
    else if (filteredBy) {
        let filter = filteredBy.replace(/_/g, ' ');
        // header += `   Filtered By: ${filter}`;
        doc.text(`${filter}` ,filteredByX, 10)
  
    }
    if (name) {
        let newName = name.replace(/-/g, ' ');
        doc.text(` ${newName}`, header, 10);
        doc.setTextColor(41, 128, 186);
    }

    doc['autoTable'](column1, data1);
    doc['autoTable'](column2, data2);

    if (name) {
        doc.save(name);
    } else {
        doc.save('report.pdf');
    }
}

  exportPdfforVoter(data: any, column: any, name?: any , filteredBy?:any) {
    // console.log(data);
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [1000, 150] 
  });
   const currentDate = new Date().toLocaleDateString('en-GB'); 
   const dateWidth = doc.getStringUnitWidth(`Date: ${currentDate}`) * doc.internal.scaleFactor; 
   const pageWidth = doc.internal.pageSize.getWidth();
   const dateX = pageWidth - dateWidth - 40; 
   const fontSize = 13;
   const filteredByX = pageWidth - dateWidth - 100; 
   const header = pageWidth - dateWidth - 260; 

   doc.setFontSize(fontSize);
   doc.setTextColor(41, 128, 186);
   doc.text(`Date: ${currentDate}`, dateX, 10);
   if(filteredBy){
    //  let filter = filteredBy.replace(/_/g, ' ');
     doc.text(`Data of : ${filteredBy}` ,filteredByX, 10)
   }
   if (filteredBy && filteredBy.length === 2) {
    const startDate = typeof filteredBy[0] === 'string' ? filteredBy[0] : filteredBy[0].toLocaleDateString('en-GB');
    const endDate = typeof filteredBy[1] === 'string' ? filteredBy[1] : filteredBy[1].toLocaleDateString('en-GB');
    doc.text(`From : ${startDate} To: ${endDate}` ,filteredByX, 10)
}



   if(name){
     let newName = name.replace(/-/g, ' ');
           doc.text(` ${newName}` ,header, 10)
     doc.setTextColor(41, 128, 186);

   }
    doc['autoTable'](column, data);
   
   if (name) {
     doc.save(name);
   } else doc.save('report.pdf');
 }


  // new export pdf in that their is time formate also
  exportPdf(data: any, column: any, name?: any, filteredBy?: any) {
    const doc = new jsPDF({
        orientation: 'landscape',
    });
    const currentDateTime = new Date().toLocaleString('en-GB'); // Get current date and time
    const dateWidth = doc.getStringUnitWidth(`Date: ${currentDateTime}`) * doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.getWidth();
    const dateX = pageWidth - dateWidth - 40;
    const fontSize = 13;
    const header = pageWidth - dateWidth - 260;

    doc.setFontSize(fontSize);
    doc.setTextColor(41, 128, 186);
    doc.text(`Date: ${currentDateTime}`, dateX, 10);

    let headerX = header; // X coordinate for the header text
    if (name) {
        let newName = name.replace(/-/g, ' ');
        const newNameWidth = doc.getStringUnitWidth(newName) * doc.internal.scaleFactor;
        doc.text(` ${newName}`, header, 10);
        headerX += newNameWidth + 20; // Add 10 units of padding between newName and FilteredBy text
        doc.setTextColor(41, 128, 186);
    }

    if (filteredBy && filteredBy.length === 2) {
        const startDate = typeof filteredBy[0] === 'string' ? filteredBy[0] : filteredBy[0].toLocaleDateString('en-GB');
        const endDate = typeof filteredBy[1] === 'string' ? filteredBy[1] : filteredBy[1].toLocaleDateString('en-GB');
        doc.text(`From : ${startDate} To: ${endDate}`, headerX, 10); // Place FilteredBy text next to the header text
    } else if (filteredBy) {
        let filter = filteredBy.replace(/_/g, ' ');
        doc.text(` ${filter}`, headerX, 10); // Place FilteredBy text next to the header text
    }
    
    // doc.autoTable(column, data);
    doc['autoTable'](column, data);

    
    if (name) {
        doc.save(name);
    } else {
        doc.save('report.pdf');
    }
}



  sendDataOnLogin(data: any) {
    return this.http.post('set-member-and-role', data);
  }

  localStorageObject() {
    let data = JSON.parse(localStorage.getItem('access_token'));
    return data;
  }
  updateLocalStorage(res?: any, name?: string) {
    var newLocalStorageData = {
      access_token: res?.access_token,
      username: res?.user?.name,
      useremail: res?.user?.email,
      role: name,
      memberRole: res?.member_role,
      userId: res?.user?.id,
      roleId: res?.user?.role_id,
    };
    localStorage.setItem('access_token', JSON.stringify(newLocalStorageData));
  }

  // Get member data by id

  getMemberdataById(id: any): Promise<any> {
    return this.http.get(`get_member_data_by_id/${id}`).toPromise();
  }

  getNatureofBusinessList(): Promise<any> {
    return this.http.get('getNOB').toPromise();
  }

  // New Payment methode

  onPayment(modal: any): Observable<any> {
    return this.https.post(
      'https://cgt.in.worldline.com/ipg/doMEPayRequest',
      modal
    );
  }
  setLocalStorageData = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    },
  };

  getPDFforService(id: any) {
    return this.https
      .get(this.base_URL + '/download_pdf/' + id, { responseType: 'blob' })
      .toPromise();
  }
  getPDFforVisa(name: any) {
    return this.https
      .get(this.base_URL + '/download-visa/' + name, { responseType: 'blob' })
      .toPromise();
  }

  getSettings() {
    return this.http.get('settings').toPromise();
  }

  createSetting(data: any) {
    return this.http.post('settings/add', data).toPromise();
  }

  updateSetting(id: any, data: any) {
    return this.http.post(`settings/update/${id}`, data).toPromise();
  }

  // get all users logs
  getAllUsersLogs(skip: number) {
    return this.http.get(`get-logs?page=${skip}`);
  }
  getProposedNomineeData() {
    return this.http.get('authorised_representatives');
  }

  //get all permission
  getAllPermissions() {
    return this.http.get(`userpermissions/permissions`).toPromise();
  }
  getUserPermission(slug: any, slug1: any) {
    // user ID , role ID
    return this.http
      .get(`userpermissions/getUserPermissions/${slug}/${slug1}`)
      .toPromise();
  }
  setPermission(data: any) {
    return this.http
      .post('userpermissions/assignPermissionToUser', data)
      .toPromise();
  }
  getUserPermissionsById(user_id: any) {
    return this.http.get(`userPermissionsByUserId/${user_id}`).toPromise()
  }
  approvalProposalData(id: any, data) {
    return this.http.post(`representativesDecisions/${id}`, data);
  }

  paymentOnListing(id?: number, data?: any): Observable<any> {
    return this.http.post(`pay-now`, data);
  }

  getVisaById(id): Observable<any> {
    return this.http.get(`visa_by_id/${id}`);
  }
  updateVisaRecommendation(id: number, data: any): Observable<any> {
    return this.http.post(`updateVisaRecommendation/${id}`, data);
  }

  getMemberById(id): Observable<any> {
    return this.http.get(`get_member_by_id/${id}`);
  }

  // pollution letter by id
  getPollutionLetterById(id): Observable<any> {
    return this.http.get(`pollution_dailog_id/${id}`);
  }

  //get coo user by ID
  getCOOById(id: any) {
    return this.http.get(`coo_by_id/${id}`).toPromise();
  }
  getLoggedInMemberData(): Observable<any> {
    return this.http.get(`member_data_by_user`);
  }
  getNewsTypeList() {
    return this.http.get(`all/news-types`)
  }
  getMemberDataByMembershipNumber(number: any) {
    return this.http.get(`member-by-membership-number/${number}`)
  }
  getUserPermissionsApi() {
    return this.http.get(`userpermissions/allFromModelHasRoles`)
  }

  getPendingRenewalList(member_id) {
    return this.http.get(`get-pending-renewal-list/${member_id}`).toPromise();
  }
  // renewal listing for user
  memberRenewalList(member_id) {
    return this.http.get(`member-membership-history/${member_id}`).toPromise();
  }
  syncWithTally(modal: any) {
    return this.http.post(`generate-invoice`, modal);
  }
  getRevisionData() {
    return this.http.get(`get_member_data_list_by_user_id`).toPromise();
  }
  getMembersDocuments(id: any) {
    return this.http.post(`get_member_docs_by_id/${id}`)
  }
  getMemberRenewalHistory(membership_name: any) {
    // return this.http.post(`report/get_pending_memberships`, membership_name)
    return this.http.post(`report/get_pending_memberships`, membership_name).toPromise()
  }
  getHwmWork() {
    return this.http.get(`report/hwm-work-queue`).toPromise()
  }
  getApprovedHwmMember(date: any) {
    return this.http.post(`report/approved-hwm-members`,date).toPromise()
  }
  getvoterlist(year){
    return this.http.post('report/eligible-voter-list',year).toPromise()
  }

 getmemberlist (): Observable<any> {
  return this.http.get('get-member-list')
 }

 nomineeDetails(id:any) {
  return this.http.get(`get-nominee-details/${id}`)
 }
 getMebershipStatus(object) {
  return this.http.post(`report/hwm-member-membership-status`, object).toPromise()
}
getMemberData(object) {
  return this.http.post(`report/member-data-report`, object).toPromise()
}

 updateNomineeDetails(data:any){
  return this.http.post(`update-nominee-details`,data)

 }
 getUserbyId(id:any) {
  return this.http.get(`get_user_by_id/${id}`)
 }
//  to update member data by admin role
 updateMemberInfoByAdmin(data: any, id: any): Observable<any> {
  return this.http.post('updateMember/' + id , data);
}
//  to update hwm member data by admin role
updateHwmMemberInfoByAdmin(data: any, id: any): Observable<any> {
  return this.http.post('hwm/direct-update/' + id , data);
}
getWorkQueue() {
  return this.http.get(`work-queues/`)
 }
 getAllWorkQueue() {
  return this.http.get(`all-work-queues`)
 }
 exportPdfForRenewal(data1 :any, column1:any , data2:any , column2:any , data3 :any ,column3:any,data4:any , column4:any ,name:any ){
   debugger
  const doc = new jsPDF({
  orientation: 'landscape'
});

const currentDate = new Date().toLocaleDateString('en-GB');
const dateWidth = doc.getStringUnitWidth(`Date: ${currentDate}`) * doc.internal.scaleFactor;
const pageWidth = doc.internal.pageSize.getWidth();
const dateX = pageWidth - dateWidth - 40;
const fontSize = 13;
const filteredByX = pageWidth - dateWidth - 100;
const header = pageWidth - dateWidth - 260;

doc.setFontSize(fontSize);
doc.setTextColor(41, 128, 186);
doc.text(`Date: ${currentDate}`, dateX, 10);

if (name) {
    let newName = name.replace(/-/g, ' ');
    doc.text(` ${newName}`, header, 10);
    doc.setTextColor(41, 128, 186);
}

let totalSum = 0;
let totalY = 0;
let activeY = 0;

if (data1 && data1.length != 0) {
    totalSum = data1.reduce((acc, curr) => acc + (curr.total || 0), 0);

    // Add header for the table
    const header = function (data) {
        doc.setFontSize(12);
        doc.setTextColor(41, 128, 186); // Blue color
        doc.text(` Renewal of (Approved , Provisionally Terminated   ) Total records :   ${data1 ? data1.length : 0}`, data.settings.margin.left, 17); // Adjust title and position as needed
    };

    doc['autoTable'](column1, data1, {
        startY: 20, // Adjust startY to make space for the header
        addPageContent: header,
    });

    totalY = doc['autoTable'].previous.finalY + 10;

    doc.setFillColor(41, 128, 186); // Blue color
    doc.rect(10, totalY - 5, 272, 10, 'F');

    doc.setTextColor(255, 255, 255); // White text color
    doc.text(` Grand Total: ${totalSum}`, 15, totalY);
    doc.setFillColor(255, 255, 255); // Reset fill color
    activeY = totalY + 10;
}

if (data2 && data2.length != 0) {
  totalSum = 0;

    if (data1) {
        doc.addPage(); // Add a new page before drawing the next table
    }
    // doc['autoTable'](column2, data2);

    const header = function (data) {
      doc.setFontSize(12);
      doc.setTextColor(41, 128, 186); // Blue color
      doc.text(` Active (Total records :   ${data2 ? data2.length : 0})`, data.settings.margin.left, 13); // Adjust title and position as needed
  };

  doc['autoTable'](column2, data2, {
      startY: 20, // Adjust startY to make space for the header
      addPageContent: header,
  });

    totalSum = data2.reduce((acc, curr) => acc + (curr.total || 0), 0);
    totalY = doc['autoTable'].previous.finalY + 10;

    doc.setFillColor(41, 128, 186); // Blue color
    doc.rect(10, totalY - 5, 272, 10, 'F');

    doc.setTextColor(255, 255, 255); // White text color
    doc.text(` Grand Total: ${totalSum}`, 15, totalY);
    doc.setFillColor(255, 255, 255); // Reset fill color
    activeY = totalY + 5;
    //const terminatedY = doc['autoTable'].previous.finalY + 10;
    // doc.text(`Terminated (Total records: ${data2.length})`, 10, terminatedY);
    // activeY = terminatedY + 5;
}

if (data3 && data3.length != 0) {
  totalSum = 0;

    if (data2) {
        doc.addPage(); // Add a new page before drawing the next table
    }
    // doc['autoTable'](column3, data3);
    const header = function (data) {
      doc.setFontSize(12);
      doc.setTextColor(41, 128, 186); // Blue color
      doc.text(` Provisionally Terminated (Total records :   ${data3 ? data3.length : 0})`, data.settings.margin.left, 13); // Adjust title and position as needed
  };

  doc['autoTable'](column3, data3, {
      startY: 20, // Adjust startY to make space for the header
      addPageContent: header,
  });
    totalSum = data3.reduce((acc, curr) => acc + (curr.total || 0), 0);
    totalY = doc['autoTable'].previous.finalY + 10;

    doc.setFillColor(41, 128, 186); // Blue color
    doc.rect(10, totalY - 5, 272, 10, 'F');

    doc.setTextColor(255, 255, 255); // White text color
    doc.text(` Grand Total: ${totalSum}`, 15, totalY);
    doc.setFillColor(255, 255, 255); // Reset fill color
    activeY = totalY + 5;
    // const provisionallyTerminatedY = doc['autoTable'].previous.finalY + 10;
    // doc.text(`Provisionally Terminated (Total records: ${data3.length})`, 10, provisionallyTerminatedY);
    // activeY = provisionallyTerminatedY + 5;
}

if (data4 && data4.length != 0) {
  totalSum = 0;

    if (data3) {
        doc.addPage(); // Add a new page before drawing the next table
    }
    // doc['autoTable'](column4, data4);
    const header = function (data) {
      doc.setFontSize(12);
      doc.setTextColor(41, 128, 186); // Blue color
      doc.text(` Terminated (Total records :   ${data4 ? data4.length : 0})`, data.settings.margin.left, 13); // Adjust title and position as needed
  };

  doc['autoTable'](column4, data4, {
      startY: 20, // Adjust startY to make space for the header
      addPageContent: header,
  });
    
    totalSum = data4.reduce((acc, curr) => acc + (curr.total || 0), 0);
    totalY = doc['autoTable'].previous.finalY + 10;

    doc.setFillColor(41, 128, 186); // Blue color
    doc.rect(10, totalY - 5, 272, 10, 'F');

    doc.setTextColor(255, 255, 255); // White text color
    doc.text(` Grand Total: ${totalSum}`, 15, totalY);
    doc.setFillColor(255, 255, 255); // Reset fill color
    activeY = totalY + 5;
}

// Draw the "Active" label at the end
// doc.text(`Active (Total records: ${data4 ? data4.length : 0})`, 10, activeY);



if (name) {
    doc.save(name);
} else {
    doc.save('report.pdf');
}

 }
 exportMultipleExcel(tableData: { data: any[], name: string }[], filterBy?: any, fileName?:any) {
  import('xlsx').then((xlsx) => {
      const currentDateTime = new Date().toLocaleString('en-GB');
      const workbook = { Sheets: {}, SheetNames: [] };

      tableData.forEach(({ data, name }, index) => {
          let header = `Table: ${name}`;

          if (filterBy && filterBy.length === 2) {
              const startDate = typeof filterBy[0] === 'string' ? filterBy[0] : filterBy[0].toLocaleDateString('en-GB');
              const endDate = typeof filterBy[1] === 'string' ? filterBy[1] : filterBy[1].toLocaleDateString('en-GB');
              header += `   From: ${startDate} - To: ${endDate}`;
          } else if (filterBy) {
              let filter = filterBy.replace(/_/g, ' ');
              header += `   Filtered By: ${filter}`;
          }

          header += `   Date & Time: ${currentDateTime}`;

          const worksheetData = [{ '': header }];
          const worksheet = xlsx.utils.json_to_sheet(worksheetData);
          const sheetName = `Sheet${index + 1}`;

          xlsx.utils.sheet_add_json(worksheet, data, { origin: 'A4' });
          workbook.SheetNames.push(name);
          workbook.Sheets[name] = worksheet;
      });

      const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
      });

      this.saveAsExcelFile(excelBuffer, fileName);
  });
}

putSiteMapData(data:any ): Observable<any> {
   return this.http.put(`update-sitemap`,data);
}

getSiteMapData() {
  return this.http.get('get-sitemap').toPromise();
}

// exportMultipleExcelForRenewal(data1: any[], data2: any[], data3: any[], data4: any[], fileName?: string, filterBy?: any) {
//   import('xlsx').then((xlsx) => {
//     const currentDateTime = new Date().toLocaleString('en-GB');

//     const workbook = { Sheets: {}, SheetNames: [] };

//     let header1 = `RENEWAL DATA (Total Records: ${data1 ? data1.length : 0})`;
//     let header2 = `ACTIVE MEMBER (Total Records: ${data2 ? data2.length : 0})`;
//     let header3 = `PROVISIONALLY TERMINATED (Total Records: ${data3 ? data3.length : 0})`;
//     let header4 = `TERMINATED (Total Records: ${data4 ? data4.length : 0})`;

//     if (filterBy && filterBy.length === 2) {
//       const startDate = typeof filterBy[0] === 'string' ? filterBy[0] : filterBy[0].toLocaleDateString('en-GB');
//       const endDate = typeof filterBy[1] === 'string' ? filterBy[1] : filterBy[1].toLocaleDateString('en-GB');
//       header1 += `   From: ${startDate} - To: ${endDate}`;
//       header2 += `   From: ${startDate} - To: ${endDate}`;
//       header3 += `   From: ${startDate} - To: ${endDate}`;
//       header4 += `   From: ${startDate} - To: ${endDate}`;
//     } else if (filterBy) {
//       let filter = filterBy.replace(/_/g, ' ');
//       header1 += `   Filtered By: ${filter}`;
//       header2 += `   Filtered By: ${filter}`;
//       header3 += `   Filtered By: ${filter}`;
//       header4 += `   Filtered By: ${filter}`;
//     }

//     header1 += `   Date & Time: ${currentDateTime}`;
//     header2 += `   Date & Time: ${currentDateTime}`;
//     header3 += `   Date & Time: ${currentDateTime}`;
//     header4 += `   Date & Time: ${currentDateTime}`;

//     const worksheetData1 = [{ '': header1 }];
//     const worksheetData2 = [{ '': header2 }];
//     const worksheetData3 = [{ '': header3 }];
//     const worksheetData4 = [{ '': header4 }];

//     const worksheet1 = xlsx.utils.json_to_sheet(worksheetData1);
//     const worksheet2 = xlsx.utils.json_to_sheet(worksheetData2);
//     const worksheet3 = xlsx.utils.json_to_sheet(worksheetData3);
//     const worksheet4 = xlsx.utils.json_to_sheet(worksheetData4);

//     xlsx.utils.sheet_add_json(worksheet1, data1, { origin: 'A4' });
//     xlsx.utils.sheet_add_json(worksheet2, data2, { origin: 'A4' });
//     xlsx.utils.sheet_add_json(worksheet3, data3, { origin: 'A4' });
//     xlsx.utils.sheet_add_json(worksheet4, data4, { origin: 'A4' });

//     workbook.SheetNames.push(`RENEWAL DATA`);
//     workbook.SheetNames.push('ACTIVE MEMBER');
//     workbook.SheetNames.push('PROVISIONALLY TERMINATED ');
//     workbook.SheetNames.push('TERMINATED ');

//     workbook.Sheets[`RENEWAL DATA`] = worksheet1;
//     workbook.Sheets['ACTIVE MEMBER'] = worksheet2;
//     workbook.Sheets['PROVISIONALLY TERMINATED '] = worksheet3;
//     workbook.Sheets['TERMINATED '] = worksheet4;

//     // Add grand total to each sheet
//     worksheet1['A' + (data1.length + 5)] = { v: `Grand Total: ${data1.length}`, t: 's' };
//     worksheet2['A' + (data2.length + 5)] = { v: `Grand Total: ${data2.length}`, t: 's' };
//     worksheet3['A' + (data3.length + 5)] = { v: `Grand Total: ${data3.length}`, t: 's' };
//     worksheet4['A' + (data4.length + 5)] = { v: `Grand Total: ${data4.length}`, t: 's' };

//     const excelBuffer: any = xlsx.write(workbook, {
//       bookType: 'xlsx',
//       type: 'array',
//     });

//     if (fileName) {
//       this.saveAsExcelFile(excelBuffer, fileName);
//     } else {
//       this.saveAsExcelFile(excelBuffer, 'MultipleTables');
//     }
//   });
// }
exportMultipleExcelForRenewal(data1: any[], data2: any[], data3: any[], data4: any[], fileName?: string, filterBy?: any) {
  import('xlsx').then((xlsx) => {
    const currentDateTime = new Date().toLocaleString('en-GB');
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet([]);

    let rowOffset = 0;

    // Add data1
    xlsx.utils.sheet_add_aoa(worksheet, [[`RENEWAL DATA (Total Records: ${data1.length})   Date & Time: ${currentDateTime}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;
    xlsx.utils.sheet_add_json(worksheet, data1, { origin: { r: rowOffset, c: 0 } });
    rowOffset += data1.length + 1;
    xlsx.utils.sheet_add_aoa(worksheet, [[`Grand Total: ${data1.length}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;

    // Add data2
    xlsx.utils.sheet_add_aoa(worksheet, [[`ACTIVE MEMBER (Total Records: ${data2.length})   Date & Time: ${currentDateTime}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;
    xlsx.utils.sheet_add_json(worksheet, data2, { origin: { r: rowOffset, c: 0 } });
    rowOffset += data2.length + 1;
    xlsx.utils.sheet_add_aoa(worksheet, [[`Grand Total: ${data2.length}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;

    // Add data3
    xlsx.utils.sheet_add_aoa(worksheet, [[`PROVISIONALLY TERMINATED (Total Records: ${data3.length})   Date & Time: ${currentDateTime}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;
    xlsx.utils.sheet_add_json(worksheet, data3, { origin: { r: rowOffset, c: 0 } });
    rowOffset += data3.length + 1;
    xlsx.utils.sheet_add_aoa(worksheet, [[`Grand Total: ${data3.length}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;

    // Add data4
    xlsx.utils.sheet_add_aoa(worksheet, [[`TERMINATED (Total Records: ${data4.length})   Date & Time: ${currentDateTime}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;
    xlsx.utils.sheet_add_json(worksheet, data4, { origin: { r: rowOffset, c: 0 } });
    rowOffset += data4.length + 1;
    xlsx.utils.sheet_add_aoa(worksheet, [[`Grand Total: ${data4.length}`]], { origin: { r: rowOffset, c: 0 } });
    rowOffset += 2;

    // Set column widths
    const columnWidths = [
      { wpx: 60 }, // Width in pixels for the first column
      { wpx: 150 }, // Width in pixels for the second column
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      // Add more objects for each additional column you want to adjust
    ];
    worksheet['!cols'] = columnWidths;

    // Add the combined worksheet to the workbook
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Renewal Data');

    // Generate Excel file
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save Excel file
    if (fileName) {
      this.saveAsExcelFile(excelBuffer, fileName);
    } else {
      this.saveAsExcelFile(excelBuffer, 'MultipleTables');
    }
  });
}








}

import { ViewportScroller } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { InterestMasterService } from 'src/app/services/interest-master.service';
import { MemberCategoriesMasterService } from 'src/app/services/member-categories.service';
import { MemberClassificationMasterService } from 'src/app/services/member-classification.service';
import { NatureOfBusinessMasterService } from 'src/app/services/nature-of-business-master.service';
import { PaymentService } from 'src/app/services/payment.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;
@Component({
  selector: 'app-regular-member-list',
  templateUrl: './regular-member-list.component.html',
  styleUrls: ['./regular-member-list.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class RegularMemberListComponent implements OnInit {
  @ViewChild('nameFilter') dataTable: Table;
  baseUrl = environment.regularMemberListURL;
  regularMemberList: any = [];
  localStorage: any;
  today: any = new Date();
  invalidDates: Array<Date> = [];
  breadcrumb: any[];
  tooltipText: string = environment.resetTooltip;
  displayMaximizable: boolean = false;
  displayVerificationDialog: boolean = false;
  filterVal: string;
  office_telephone: any = '';
  website: any = '';
  email: any = '';
  company_name: any = '';
  membership_no: any = '';
  filterValues: any = [];
  inprogress: string = 'In Progress';
  datalength: any;
  statusObject = environment.status;
  roleObject = environment.roles;
  memberId: number;
  receivedOnDate: any;
  chequeDate: any;
  submitted = false;
  applicationDate: any;
  getAPIData: any = [];
  showApproveForm: boolean = false;
  showRejectForm: boolean = false;
  Modal: boolean = false;
  submittedApprove = false;
  submittedReject = false;
  id: number;
  data: any;
  date1: any;
  cols: any[];
  exportColumns: any[];
  pdfData: any[];
  admissionFee: any = [];
  capitalFee: any = [];
  programFee: any = [];
  admissionFeeTotal: number = 0;
  capitalFeeTotal: number = 0;
  programFeeTotal: number = 0;
  total: number = 0;
  existing_nos: any;
  max_member_allowed: any;
  multipleFields: any = [{ key: null, value: null, dropdownKey: [] }];
  objectKeysArray: any = [];
  payStatus: boolean = false;
  AlertDialog: boolean = false;
  showListing: boolean = true;
  showRenewalListing: boolean = true;
  @Input() hideDataForAnotherComponent: boolean = true;
  @Input() regularMemberListData: boolean = false;
  keyNames: any = [
    'member_category',
    'turn_over',
    'member_classification',
    'Nature of Business',
  ];
  paymentMode: any = [
    { label: 'Cheque', value: 'Cheque' },
    { label: 'RTGS', value: 'RTGS' },
    { label: 'NEFT', value: 'NEFT' },
    { label: 'IMPS', value: 'IMPS' },
  ];
  objectKeys: any = {};
  colFilter = [];
  status = [
    { label: 'Verified', value: 'verified' },
    { label: 'Verification Rejected', value: 'Verification Rejected' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'In Progress', value: 'in progress' },
  ];

  verificationrejectionForm = this.fb.group({
    remark: [null, Validators.required],
    approval: [null],
  });
  rejectionModal: boolean = false;
  selectedMemberData: any[] = [];
  excelData: any[];
  filteredXLSData: any[];
  filteredPDFData: any[];
  turnOverData: any = [];
  areaOfInterest: any = [];
  formRecivedDate: any;
  minDate: Date;
  maxDate: Date;
  classificationListData: any = [];
  categoryListData: any = [];
  natureofBusinessListData: any = [];
  chequeMinDate: Date;
  chequeMaxDate: Date;
  chequeDateState: boolean = true;
  minApprovalDate: Date;
  maxApprovalDate: Date;
  successState: boolean;
  message: any;
  RoleId: any;
  UserId: any;
  permission: any = [];
  enableDeleteButton: boolean = true;
  enableEditButton: boolean = false;
  enableApprovalButton: boolean = false;
  paymentResponse: { formState: boolean; merchantRequest: string };
  slug: any;
  enableViewButton: boolean = false;
  enableVerificationButton: boolean = false;
  enableAddButton: boolean = false;
  userPermission: any;
  renewalListing: any = [];
  draftData: any = [];
  response: any = [];
  storageData: any;

  constructor(
    private apiservice: ApiService,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private paymentservice: PaymentService,
    public apiService: ApiService,
    private scroller: ViewportScroller,
    private interestMasterService: InterestMasterService,
    private memberClassification: MemberClassificationMasterService,
    private memberCategory: MemberCategoriesMasterService,
    private natureofBusiness: NatureOfBusinessMasterService,
    private activated: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private utility: utility,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.storageData = JSON.parse(localStorage.getItem('access_token'));
    this.slug = this.activated.snapshot.params;
    if (this.slug?.id && this.slug?.status && this.slug?.order_id) {
      let statusDetail = {
        status: this.slug?.status == 'Success' ? 'Payment Success' : 'Payment Failed'
      }
      this.paymentservice.regularMemberFees(this.slug?.order_id, this.slug?.id, statusDetail).subscribe((res: any) => {
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });
        this.message = {
          form: 'regular-member-edit',
          message: res.message,
        };
        this.successState = true;
      });
    }
    else {
      let memberRole = [this.roleObject.nominee1, this.roleObject.nominee2];
      this.breadcrumb = [
        {
          title: memberRole.includes(this.localStorage.role)
            ? 'My Membership'
            : 'Regular Member',
          subTitle: 'Dashboard',
        },
      ];
      let today1 = new Date();
      let month1 = today1.getMonth();
      let year1 = today1.getFullYear();
      let prevMonth1 = month1 === 11 ? 0 : month1 - 1;
      let prevYear1 = prevMonth1 === 0 ? year1 - 1 : year1;
      let nextMonth1 = month1 === 11 ? 0 : month1 + 1;
      let nextYear1 = nextMonth1 === 0 ? year1 + 1 : year1;
      this.minDate = new Date();
      this.maxDate = new Date();

      this.maxDate.setMonth(nextMonth1 + 2);
      this.maxDate.setFullYear(nextYear1);
      this.minDate.setMonth(prevMonth1 - 2);
      this.minDate.setFullYear(prevYear1);

      this.spinner.show();
      this.getImage();
      this.getAPIData.representatives = [];
      this.getRegularMemberListFunction();
      this.invalidDates.push(this.today);
      let permisionRoles = [
        this.roleObject?.admin,
        this.roleObject?.staff,
        this.roleObject?.president,
        this.roleObject?.ceo,
      ];
      if (permisionRoles?.includes(this.localStorage?.role)) {
        this.colFilter = [
          { field: 'S. No.' },
          { field: 'action' },
          { field: 'status' },
          { field: 'member_since' },
          { field: 'membership_no' },
          { field: 'company_name' },
          { field: 'email' },
          { field: 'website' },
          { field: 'office_telephone' },
        ];
      } else if (!permisionRoles?.includes(this.localStorage?.role)) {
        this.colFilter = [
          { field: 'S. No.' },
          { field: 'action' },
          { field: 'payment' },
          { field: 'status' },
          { field: 'member_since' },
          { field: 'membership_no' },
          { field: 'company_name' },
          { field: 'email' },
          { field: 'website' },
          { field: 'office_telephone' },
        ];
      }
      this.getData();
      this.cols = [
        { field: 'sr_no', header: 'Sr No' },
        { field: 'company_name', header: 'Company Name' },
        // { field: 'invoice_payment', header: 'Invoice Payment' },
        { field: 'member_since', header: 'Member Since' },
        { field: 'email', header: 'Email' },
        { field: 'office_telephone', header: 'Office Telephone' },
        { field: 'gst_number', header: 'GST number' },
        { field: 'nominee1Name', header: 'Nominee 1 Name' },
        { field: 'nominee2Name', header: 'Nominee 2 Name' },
      ];
      this.exportColumns = this.cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
      if (this.hideDataForAnotherComponent) {
        this.getTurnoverData();
        this.getInterest();
        this.getMemberClassification();
        this.getMemberCategory();
        this.natureOfBusinessList();
        this.getMemberRole();
      }
      this.getUserPermissions();
      if (this.localStorage.role == 'Nominee 1' || this.localStorage.role == 'Nominee 2') {
        this.getRenewalList();
        this.showTable('listing')
      }
    }
  }
  // getAppRegistered(applicant_registered?: any) {
  //   return JSON?.parse(applicant_registered);
  // }
  // turnover data list
  getTurnoverData() {
    this.apiService.getTurnoverData().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.component,
          value: element?.component,
        };
        this.turnOverData.push(object);
      });
    });
  }
  // area of interest list
  getInterest() {
    this.interestMasterService.getInterestArea().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.interest_area,
          value: element?.interest_area,
        };
        this.areaOfInterest.push(object);
      });
    });
  }
  // get member classification list
  getMemberClassification() {
    this.memberClassification
      .getMemberClassificationMaster()
      .subscribe((res: any) => {
        res?.data?.forEach((element) => {
          let object = {
            label: element?.name,
            value: element?.name,
          };
          this.classificationListData.push(object);
        });
      });
  }
  // get member category list
  getMemberCategory() {
    this.memberCategory.getMemberCategoriesMaster().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.name,
          value: element?.name,
        };
        this.categoryListData.push(object);
      });
    });
  }
  // get nature of business list
  natureOfBusinessList() {
    this.natureofBusiness.getNatureofBusinessList().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.name,
          value: element?.name,
        };
        this.natureofBusinessListData.push(object);
      });
    });
  }
  // get regular member list api call
  getRegularMemberListFunction() {
    this.apiservice.getregularMemberList().subscribe(
      (res: any) => {
        this.response = res;
        this.regularMemberList = res?.data;
        this.draftData?.push(res?.member_data[0]?.member_data_json);
        this.datalength = this.regularMemberList.length;
        this.pdfData = [];
        this.excelData = [];
        const validKey = [
          'company_name',
          'applicant_constitution',
          'registered_by',
          'registration_number',
          'email',
          'website',
          'capital_investment',
          'applicant_registered',
          'gst_number',
          'status',
          'member_category',
          'member_classification',
          'turn_over',
        ];
        for (const key in this.regularMemberList[0]) {
          if (validKey.includes(key)) {
            this.objectKeysArray.push({ name: key });
          }
        }
        this.objectKeysArray.push({ name: 'Area of Interest' });
        this.objectKeysArray.push({ name: 'Nature of Business' });
        this.regularMemberList.forEach((response) => {
          response.nominee_Interest =
            JSON.stringify(response?.nominee1_interest ?? '') +
            JSON.stringify(response?.nominee2_interest ?? '');
        });
        this.verifiedMembers;
      },
      (error: any) => { }
    );
  }
  get verifiedMembers(): any[] {
    // Filter the regularMemberList to show only verified members
    // console.log(this.verifiedMembers)
    return this.regularMemberList.filter(member => member.status == 'Verified');
  }
  approveMemberById() {
    if (this.regularMemberApprovalForm.valid) {
      let meetingDate = moment(
        this.regularMemberApprovalForm.value.meetingdate
      ).format('YYYY/MM/DD');
      let meetingRejectDate = moment(
        this.regularMemberApprovalForm.value.meetingdatereject
      ).format('YYYY/MM/DD');
      let formData = new FormData();
      this.data = this.regularMemberApprovalForm.value;
      if (this.showApproveForm == true) {
        formData.append('meeting_date', meetingDate);
        formData.append('decision', 'Approved');
      } else {
        formData.append('meeting_date', meetingRejectDate);
        formData.append('reason', this.data.reason);
        formData.append('decision', 'Rejected');
      }
      this.apiservice
        .approveRegularMemberByComittee(this.id, formData)
        .subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            setTimeout(() => {
              this.Modal = false;
            }, 1000);
            this.getRegularMemberListFunction();
          }
        });
    }
    if (this.showApproveForm) {
      this.submitted = true;
    }
    if (this.showRejectForm) {
      this.submittedReject = true;
    }
  }
  // reset filter
  reset(nameFilter :any) {
    nameFilter?.reset();
    this.dataTable?.reset()
    this.filterVal = '';
    this.email = '';
    this.office_telephone = '';
    this.website = '';
    this.company_name = '';
    this.membership_no = '';

    // if(nameFilter){
    //   if (nameFilter.filters['membership_no']) nameFilter.filters['membership_no']['value'] = '';
    //   if (nameFilter.filters['company_name']) nameFilter.filters['company_name']['value'] = '';
    //   if (nameFilter.filters['email']) nameFilter.filters['email']['value'] = '';
    //   if (nameFilter.filters['office_telephone'])
    //     nameFilter.filters['office_telephone']['value'] = '';
    //   if (nameFilter.filters['website']) nameFilter.filters['website']['value'] = '';
    //   for (let i = 0; i < this.multipleFields.length; i++) {
    //     let name = this.multipleFields[i]?.key?.name;
    //     if (this.dataTable.filters[name]) {
    //       this.dataTable.filters[name]['value'] = '';
    //     }
    //   }
    // }


//  Object.keys(this.dataTable.filters).forEach((key) => {
//       this.dataTable.filters[key]['value'] = null;

//     });
       
    this.multipleFields = [{ key: '', value: '', dropdownKey: '' }];
  } 
  // view function
  details: any = [];
  companyAddress: any = [];
  applicant_registered: any = {};
  showMaximizableDialog(res: any) {
    this.selectedMemberData = []
    this.memberId = res.id;
    this.getVerificationData();
    this.displayMaximizable = true;
    this.apiService.getMemberById(res.id).subscribe((res: any) => {
      this.selectedMemberData.push(res.data);
    })
  }

  annual_fee: any = {};
  annualFeeTotal: any = 0;
  getVerificationData() {
    this.apiservice.getDataVerification(this.memberId).subscribe((res: any) => {
      this.getAPIData = res.data;

      this.annual_fee = res.annual_fee;
      if (this.getAPIData.mode_of_payment == 'offline') {
        this.verificationForm.setControl('bank_name', new FormControl(''));
        this.verificationForm.setControl(
          'cheque_date',
          new FormControl('', [Validators.required])
        );

        this.verificationForm.setControl('cheque_no', new FormControl(''));
        this.verificationForm.setControl(
          'paid_by',
          new FormControl('', [Validators.required])
        );

      } else {
        this.verificationForm.controls['bank_name'].setValue('bank');
        this.verificationForm.controls['cheque_no'].setValue('89');
        this.verificationForm.controls['cheque_date'].setValue('38');
        this.verificationForm.controls['paid_by'].setValue('38');
      }
      this.existing_nos = res.existing_nos;
      this.max_member_allowed = res.max_member_allowed;

      var feesData = this.getAPIData.type_of_membership_for_regular;

      feesData.forEach((res: any) => {
        if (res.id == 1) {
          this.admissionFee.push(res);
          this.admissionFeeTotal =
            parseFloat(this.admissionFee[0].membership_fee) +
            (this.admissionFee[0].membership_fee *
              this.admissionFee[0].tax_percentage) /
            100;
        } else if (res.id == 2) {
          this.capitalFee.push(res);
          this.capitalFeeTotal =
            parseFloat(this.capitalFee[0].membership_fee) +
            (this.capitalFee[0].membership_fee *
              this.capitalFee[0].tax_percentage) /
            100;
        } else if (res.id == 8) {
          this.programFee.push(res);
          this.programFeeTotal =
            parseFloat(this.programFee[0].membership_fee) +
            (this.programFee[0].membership_fee *
              this.programFee[0].tax_percentage) /
            100;
        }
      });

      this.annualFeeTotal =
        parseFloat(this.annual_fee.membership_fee) +
        (this.annual_fee.membership_fee * this.annual_fee.tax_percentage) / 100;
      this.total =
        this.admissionFeeTotal +
        this.capitalFeeTotal +
        this.programFeeTotal +
        this.annualFeeTotal;
    });
  }
  // show verification dialog
  showVerificationDialog(res: any, type: string) {
    this.formRecivedDate = new Date(res.created_at);
    this.date1 = this.formRecivedDate;
    this.verificationForm.patchValue({
      form_received_on: this.formRecivedDate,
    });
    this.memberId = res.id;
    if (type == 'approve') {
      this.details = [];
      this.details.push(res);
      this.displayVerificationDialog = true;
      this.getData();
    } else if (type == 'reject') {
      this.rejectionModal = true;
    }
  }

  // approve reject form control
  regularMemberApprovalForm = this.fb.group({
    meetingdate: new FormControl(''),
    reason: new FormControl(''),
    meetingdatereject: new FormControl(''),
  });
  // aprrove reject dialog
  showConfirmationDialog(res: any, value: string) {
    this.maxApprovalDate = new Date();
    this.minApprovalDate = new Date(res.application_found_complete_on);
    if (res.status == 'Verified') {
      this.id = res.id;
      this.Modal = true;
      if (value == 'approvedialog') {
        this.showApproveForm = true;
        this.showRejectForm = false;

        this.regularMemberApprovalForm.setControl(
          'meetingdate',
          this.fb.control('', [Validators.required])
        );
        this.regularMemberApprovalForm.setControl(
          'meetingdatereject',
          this.fb.control('')
        );
        this.regularMemberApprovalForm.setControl(
          'reason',
          this.fb.control('')
        );
      } else if (value == 'rejectdialog') {
        this.showRejectForm = true;
        this.showApproveForm = false;
        this.regularMemberApprovalForm.setControl(
          'meetingdate',
          this.fb.control('')
        );
        this.regularMemberApprovalForm.setControl(
          'meetingdatereject',
          this.fb.control('', [Validators.required])
        );
        this.regularMemberApprovalForm.setControl(
          'reason',
          this.fb.control('', [Validators.required])
        );
      }
    }
  }
  // image path url
  getImage(path?: string, end_point?: any): SafeUrl {
    let returnValue: any;
    returnValue = this.baseUrl;
    if (end_point == 'logo') {
      returnValue = returnValue + end_point + '/' + path;
    } else if (end_point == 'profile1') {
      returnValue = returnValue + end_point + '/' + path;
    } else if (end_point == 'profile2') {
      returnValue = returnValue + end_point + '/' + path;
    } else if (end_point == 'enclosure_documents') {
      returnValue = environment.regularMemberEnclosure + end_point + '/' + path;
    } else if (end_point == 'invoice') {
      returnValue = environment.DocReceiptBaseURL + path;
      // returnValue = returnValue + end_point + '/' + path;
    }
    return this.sanitizer.bypassSecurityTrustUrl(returnValue);
  }

  //*****************************************verification process**********************************************
  // form group
  verificationForm = this.fb.group({
    form_received_on: new FormControl('', Validators.required),
    form_checked_on: new FormControl('', Validators.required),
    gaps_in_application: new FormControl('', Validators.required),
    application_found_complete_on: new FormControl('', Validators.required),
    cheque_no: new FormControl(''),
    bank_name: new FormControl(''),
    cheque_date: new FormControl('', Validators.required),
    paid_by: new FormControl('', Validators.required),
    approval: new FormControl(null),
  });
  // verifiy function
  verifyData(verify: FormGroupDirective) {
    if (this.verificationForm.valid) {
      this.receivedOnDate = moment(
        this.verificationForm.value.form_received_on
      ).format('YYYY/MM/DD');
      ('2022-11-11T00:09:46.000000Z');
      this.chequeDate = moment(this.verificationForm.value.cheque_date).format(
        'YYYY-MM-DDT18:30:00'
      );

      this.applicationDate = moment(
        this.verificationForm.value.application_found_complete_on
      ).format('YYYY/MM/DD');
      let form_checked_on = moment(
        this.verificationForm.value.form_checked_on
      ).format('YYYY/MM/DD');
      this.verificationForm.patchValue({
        approval: 1,
        form_received_on: this.receivedOnDate,
        cheque_date: this.chequeDate,
        application_found_complete_on: this.applicationDate,
        form_checked_on: form_checked_on,
      });
      let formData = new FormData();
      let data = this.verificationForm.value;
      if (this.getAPIData.mode_of_payment == 'offline') {
        for (const [key, value] of Object.entries(data)) {
          formData.append(`${key}`, `${value}`);
        }
      } else {
        for (const [key, value] of Object.entries(data)) {
          if (
            key != 'paid_by' &&
            key != 'bank_name' &&
            key != 'cheque_no' &&
            key != 'cheque_date'
          )
            formData.append(`${key}`, `${value}`);
        }
      }
      if (this.verificationForm.valid) {
        this.apiservice
          .approveRegularMember(this.memberId, formData)
          .subscribe((res: any) => {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            setTimeout(() => {
              this.displayVerificationDialog = false;
              verify.resetForm();
            }, 1500);
            this.getRegularMemberListFunction();
          });
      } else if (this.verificationForm.invalid) {
        this.messageService.add({
          severity: 'error',
          detail: 'Please Fill All the Details!',
        });
      }
      this.submitted = true;
    }
  }
  // get data for verification
  getData() {
    if (this.displayVerificationDialog == true) {
      this.getVerificationData();
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.verificationForm.controls;
  }
  get function() {
    return this.regularMemberApprovalForm.controls;
  }
  get h() {
    return this.verificationrejectionForm.controls;
  }
  @ViewChild('regularverify') regularverify: NgForm;
  onDialogClose() {
    this.regularverify.resetForm();
  }

  onRejectPress(form) {
    this.verificationrejectionForm.patchValue({
      approval: 0,
    });

    if (this.verificationrejectionForm.valid) {
      this.apiservice
        .approveRegularMember(
          this.memberId,
          this.verificationrejectionForm.value
        )
        .subscribe((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          setTimeout(() => {
            this.rejectionModal = false;
            form.resetForm();
          }, 1500);
          this.getRegularMemberListFunction();
        });
    } else if (this.verificationForm.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
    }
    // }
  }

  getExtension(link: any) {
    let value = link.split('.');
    return value[1] == 'pdf' ? true : false;
  }

  // payment process razorpay
  // member_id: any;
  payment(data: any): void {

    // this.member_id = data.payment[0]?.member_id;
    if (data.mode_of_payment == 'online') {
      let payload = {
        payment_id: data.payment_id,
        id: data.id,
        url: environment.frontURL + '/user-panel/regular-member-list/' + data.id,
        module: 'Regular Member Form'
      }
      this.apiservice.paymentOnListing(data.payment_id, payload).subscribe((res: any) => {
        let gatewayResponse: string = res.gatewayResponse;
        this.payStatus = true;
        this.paymentResponse = {
          formState: true,
          merchantRequest: gatewayResponse,
        };
      });
      // let options = {
      //   key: environment.rezorpay_key,
      //   currency: 'INR',
      //   base_amount: data.payment[0]?.amount,
      //   name: 'UCCI',
      //   image: environment.rezorpay_logo,
      //   order_id: data.payment[0]?.order_id,
      //   handler: function (response: any) {
      //     var event = new CustomEvent('payment.success', {
      //       detail: response,
      //       bubbles: true,
      //       cancelable: true,
      //     });
      //     window.dispatchEvent(event);
      //   },
      //   prefill: {
      //     name: '',
      //     email: '',
      //     contact: '',
      //   },
      //   notes: {
      //     address: '',
      //   },
      //   theme: {
      //     color: '#0ec6c6',
      //   },
      // };
      // var rzp1 = new Razorpay(options);
      // rzp1.open();
      // rzp1.on('payment.failed', (response: any) => {
      //   let statusDetail = {
      //     status: 'Payment Failed',
      //     payment_id: response.error.metadata.payment_id,
      //     razorpay_signature: '',
      //     description: response.error.description,
      //   };
      //   this.paymentservice.regularMemberFees(
      //     response.error.metadata.order_id,
      //     data.member_id,
      //     statusDetail
      //   );
      // });
    }
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      type: 'regular-member-edit',
    };
    // let statusDetail = {
    //   status: 'Payment Success',
    //   payment_id: event.detail.razorpay_payment_id,
    //   razorpay_signature: event.detail.razorpay_signature,
    //   description: 'Payment Success',
    // };
    // this.paymentservice
    //   .regularMemberFees(
    //     event.detail.razorpay_order_id,
    //     this.member_id,
    //     statusDetail
    //   )
    //   .subscribe((res: any) => {
    //     if (res.code == 200) {
    //       this.messageService.add({
    //         severity: 'success',
    //         detail: res.message,
    //       });
    //       this.getRegularMemberListFunction();
    //     }
    //   });
  }

  onXLSExport() {
    this.excelData = [];
    let filteredBy
    this.filteredXLSData = [];
    if (this.dataTable.filteredValue != null) {
      this.filteredXLSData = this.dataTable.filteredValue;
      filteredBy= this.utility.getFilteredByName(this.dataTable)
    } else if (this.dataTable.filteredValue == null) {
      this.filteredXLSData = this.regularMemberList;
    }
    for (let i = 0; i < this.filteredXLSData.length; i++) {
      let applicant_registered: string;
      if (this.filteredXLSData[i]?.applicant_registered?.includes('[')) {
        applicant_registered = JSON.parse(
          this.filteredXLSData[i]?.applicant_registered
        );
        applicant_registered = applicant_registered[0]
      } else
        applicant_registered = this.filteredXLSData[i]?.applicant_registered;
      let ia1 = [];
      let ia2 = [];

      for (
        let j = 0;
        j < this.filteredXLSData[i]?.nominee1_interest?.length;
        j++
      ) {
        if (this.filteredXLSData[i]?.nominee1_interest?.length > 0) {
          ia1.push(
            this.filteredXLSData[i]?.nominee1_interest[j]?.interest_area
          );
        } else ia1 = null;
      }

      for (
        let j = 0;
        j < this.filteredXLSData[i]?.nominee2_interest?.length;
        j++
      ) {
        if (this.filteredXLSData[i]?.nominee2_interest?.length > 0) {
          ia2.push(
            this.filteredXLSData[i]?.nominee2_interest[j]?.interest_area
          );
        } else ia2 = null;
      }

      let memberSince = this.filteredXLSData[i]?.member_since;
      let formattedDate
      if (memberSince) {
        let parts = memberSince.split('-');
        let date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));

        formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        console.log(formattedDate, "formattedDate");
      }
      this.excelData.push({
        'Sr No': i + 1,
        Status: this.filteredXLSData[i]?.status,
        'Company Name': this.filteredXLSData[i]?.company_name,
        'Invoice Amount': this.filteredXLSData[i]?.payment[0]?.amount,
        'Nature of Bussiness': this.filteredXLSData[i]?.nob?.name,
        'Member Classification':
          this.filteredXLSData[i]?.member_classification?.name,
        'Membership No.': this.filteredXLSData[i]?.membership_no ?? '-',
        'Member Since': formattedDate ? formattedDate : ' ',
        'Constitution of the Applicant':
          this.filteredXLSData[i]?.applicant_constitution,
        'GST Number': this.filteredXLSData[i]?.gst_number,
        CIN: this.filteredXLSData[i]?.cin_number,
        'Office Telephone': this.filteredXLSData[i]?.office_telephone,
        'Residental Telephone': this.filteredXLSData[i]?.residential_telephone,
        'Average Number of Fulltime Employee':
          this.filteredXLSData[i]?.avg_no_of_fulltime_employee,
        'Registered Address':
          this.filteredXLSData[i]?.company_address[0]?.address,
        'Registered State': this.filteredXLSData[i]?.company_address[0]?.state,
        'Registered City': this.filteredXLSData[i]?.company_address[0]?.city,
        'Registered Pincode':
          this.filteredXLSData[i]?.company_address[0]?.pin_code,
        'Registered Website': this.filteredXLSData[i]?.website,
        'Registered Email': this.filteredXLSData[i]?.email,
        'Main Unit Address':
          this.filteredXLSData[i]?.company_address[1]?.address,
        'Main Unit State': this.filteredXLSData[i]?.company_address[1]?.state,
        'Main Unit City': this.filteredXLSData[i]?.company_address[1]?.city,
        'Main Unit Pincode':
          this.filteredXLSData[i]?.company_address[1]?.pin_code,
        'Main Unit Website': this.filteredXLSData[i]?.main_website,
        'Main Unit Email': this.filteredXLSData[i]?.main_email,
        'Main Capital Investment': this.filteredXLSData[i]?.capital_investment,
        'Registration Number': this.filteredXLSData[i]?.registration_number,
        About: this.filteredXLSData[i]?.about,
        'Sales Turnover': this.filteredXLSData[i]?.turn_over?.component,
        'Member Category': this.filteredXLSData[i]?.member_category?.name,
        'Date of Establishment': moment(
          this.filteredXLSData[i]?.establishment_date
        ).format('DD/MM/YYYY'),
        'Is the Applicant Registered with UCCI Under': applicant_registered,
        'Registered by': this.filteredXLSData[i]?.registered_by,
        'Other Information': this.filteredXLSData[i]?.other_information
          ? this.filteredXLSData[i]?.other_information
          : '-',
        'Nominee a Member of any Other Associations': this.filteredXLSData[i]
          ?.other_profession
          ? this.filteredXLSData[i]?.other_profession
          : '-',
        'Nominee 1 Name': this.filteredXLSData[i]?.nominee1?.name,
        'Nominee 1 Designation': this.filteredXLSData[i]?.nominee1?.designation,
        'Nominee 1 PAN': this.filteredXLSData[i]?.nominee1?.pan_number,
        'Nominee 1 Date of Birth': moment(
          this.filteredXLSData[i]?.nominee1?.date_of_birth
        ).format('DD/MM/YYYY'),
        'Nominee 1 Email': this.filteredXLSData[i]?.nominee1?.email,
        'Nominee 1 Office Phone':
          this.filteredXLSData[i]?.nominee1?.office_phone,
        'Nominee 1 Mobile': this.filteredXLSData[i]?.nominee1?.mobile,
        'Nominee 1 Address': this.filteredXLSData[i]?.nominee1_address?.address,
        'Nominee 1 State': this.filteredXLSData[i]?.nominee1_address?.state,
        'Nominee 1 City': this.filteredXLSData[i]?.nominee1_address?.city,
        'Nominee 1 Pincode':
          this.filteredXLSData[i]?.nominee1_address?.pin_code,
        'Nominee 1 Department': this.filteredXLSData[i]?.nominee1?.department,
        'Nominee 1 Industry Area': ia1 != null ? ia1.toString() : '-',
        'Nominee 1 Other Information':
          this.filteredXLSData[i]?.nominee1?.other_profession,
        'Nominee 2 Name': this.filteredXLSData[i]?.nominee2?.name,
        'Nominee 2 Designation': this.filteredXLSData[i]?.nominee2?.designation,
        'Nominee 2 PAN': this.filteredXLSData[i]?.nominee2?.pan_number,
        'Nominee 2 Date of Birth': moment(
          this.filteredXLSData[i]?.nominee2?.date_of_birth
        ).format('DD/MM/YYYY'),
        'Nominee 2 Email': this.filteredXLSData[i]?.nominee2?.email,
        'Nominee 2 Office Phone':
          this.filteredXLSData[i]?.nominee2?.office_phone,
        'Nominee 2 Mobile': this.filteredXLSData[i]?.nominee2?.mobile,
        'Nominee  Address': this.filteredXLSData[i]?.nominee2_address?.address,
        'Nominee 2 State': this.filteredXLSData[i]?.nominee2_address?.state,
        'Nominee 2 City': this.filteredXLSData[i]?.nominee2_address?.city,
        'Nominee 2 Pincode':
          this.filteredXLSData[i]?.nominee2_address?.pin_code,
        'Nominee 2 Department': this.filteredXLSData[i]?.nominee2?.department,
        'Nominee 2 Industry Area': ia2 != null ? ia2.toString() : '-',
        'Nominee 2 Other Information':
          this.filteredXLSData[i]?.nominee2?.other_profession,
        'Representative Name':
          this.filteredXLSData[i]?.representatives[0]?.representative_name,
        'Representative Designation':
          this.filteredXLSData[i]?.representatives[0]
            ?.representative_designation,
        Date: moment(
          this.filteredXLSData[i]?.representatives[0]?.signed_on
        ).format('DD/MM/YYYY'),
        Place: this.filteredXLSData[i]?.representatives[0]?.place,
        'Form Received On': this.filteredXLSData[i]?.form_received_on
          ? moment(this.filteredXLSData[i]?.form_received_on).format(
            'DD/MM/YYYY'
          )
          : '-',
        'Form Checked for Completeness On': moment(
          this.filteredXLSData[i]?.form_checked_on
        ).format('DD/MM/YYYY'),
        'Checked By': this.filteredXLSData[i]?.nominee1?.name,
        'Payment Mode': this.filteredXLSData[i]?.mode_of_payment,
        'Date Application Found Complete On': moment(
          this.filteredXLSData[i]?.application_found_complete_on
        ).format('DD/MM/YYYY'),
        'Annual Turnover': this.filteredXLSData[i]?.turn_over?.component,
      });
    }
    this.apiService.exportExcel(this.excelData, 'Regular-Member-List' , filteredBy);
  }
  onPDFxport() {
    this.pdfData = [];
    this.filteredPDFData = [];
    let filteredBy
    if (this.dataTable.filteredValue != null) {
      this.filteredPDFData = this.dataTable.filteredValue;
      filteredBy= this.utility.getFilteredByName(this.dataTable)
       
    } else if (this.dataTable.filteredValue == null) {
      this.filteredPDFData = this.regularMemberList;
    }

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      
      let memberSince = this.filteredPDFData[i]?.member_since;
      let formattedDate
      if (memberSince) {
        let parts = memberSince.split('-');
        let date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));

        formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        console.log(formattedDate, "formattedDate");
      }

      this.pdfData.push({
        sr_no: i + 1,
        company_name: this.filteredPDFData[i]?.company_name,
        'invoice_payment': this.filteredPDFData[i]?.payment[0]?.amount,
        member_since: formattedDate ? formattedDate : ' ',
        email: this.filteredPDFData[i]?.email,
        office_telephone: this.filteredPDFData[i]?.office_telephone,
        gst_number: this.filteredPDFData[i]?.gst_number,
        nominee1Name: this.filteredPDFData[i]?.nominee1?.name,
        nominee2Name: this.filteredPDFData[i]?.nominee2?.name,
      });
    }
    this.apiService.exportPdf(
      this.pdfData,
      this.exportColumns,
      'Regular-Member-List',
      filteredBy
    );
  }
  // *******************************************header filter dropdown process function *****************************************
  search(event: any, index: number, fields?: any) {
    
    Object.keys(this.dataTable.filters).forEach((key) => {
      this.dataTable.filters[key]['value'] = null;

    });

    this.multipleFields.forEach((res: any) => {
      if (!this.keyNames?.includes(res?.key?.name)) {
          this.filterValues.push({ value: res?.value, columnName: res?.key?.name == 'Area of Interest' ? 'nominee_Interest' : res?.key?.name, matchMode: 'contains' });
      } else if (res?.key?.name == 'turn_over') {
          this.filterValues.push({ value: res?.value, columnName: 'turn_over.component', matchMode: 'contains' });
      } else if (res?.key?.name == 'member_category') {
          this.filterValues.push({ value: res?.value, columnName: 'member_category.name', matchMode: 'contains' });
      } else if (res?.key?.name == 'member_classification') {
          this.filterValues.push({ value: res?.value, columnName: 'member_classification.name', matchMode: 'contains' });
      } else if (res?.key?.name == 'Nature of Business') {
          this.filterValues.push({ value: res?.value, columnName: 'nob.name', matchMode: 'contains' });
      }
  });
  


    if (this.filterVal) {
      this.filterValues.push({ value: this.filterVal, columnName: 'contains', matchMode: 'contains' });
  }
  }

  applyFilter() {
    this.filterValues.forEach((filter) => {
        this.dataTable.filter(filter.value, filter.columnName, filter.matchMode);
    });
  
    this.filterValues = [];
  }

//   search(event: any, index: number, fields?: any) {
//     // Clear existing filter values
//     Object.keys(this.dataTable.filters).forEach((key) => {
//         this.dataTable.filters[key]['value'] = null;
//     });

//     // Store filter values
//     this.multipleFields.forEach((res: any) => {
//         if (!this.keyNames?.includes(res?.key?.name)) {
//             this.filterValues.push({ value: res?.value, columnName: res?.key?.name == 'Area of Interest' ? 'nominee_Interest' : res?.key?.name, matchMode: 'contains' });
//         } else if (res?.key?.name == 'turn_over') {
//             this.filterValues.push({ value: res?.value, columnName: 'turn_over.component', matchMode: 'contains' });
//         } else if (res?.key?.name == 'Nature of Business') {
//             this.filterValues.push({ value: res?.value, columnName: 'nob.name', matchMode: 'contains' });
//         }
//     });

//     // Apply global filter value if available
//     if (this.filterVal) {
//         this.filterValues.push({ value: this.filterVal, columnName: 'contains', matchMode: 'contains' });
//     }
// }

  
  // turnover and area of interest of business dropdown
  getDropdownEvent(event: any, index: any) {
    if (event == 'turn_over') {
      this.multipleFields[index].dropdownKey = this.turnOverData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'member_classification') {
      this.multipleFields[index].dropdownKey = this.classificationListData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'member_category') {
      this.multipleFields[index].dropdownKey = this.categoryListData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'Area of Interest') {
      this.multipleFields[index].dropdownKey = this.areaOfInterest;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'Nature of Business') {
      this.multipleFields[index].dropdownKey = this.natureofBusinessListData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'status') {
      this.multipleFields[index].dropdownKey = [
        { label: 'Verified', value: 'Verified' },
        { label: 'Verification Rejected', value: 'Verification Rejected' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'In Progress', value: 'In Progress' },
      ];
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else {
      this.multipleFields[index].dropdownKey = [];
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    }
  }
  addMoreFilters() {
    this.multipleFields.push({ key: null, value: null, dropdownKey: [] });
  }
  removevalue(i) {
    let name = this.multipleFields[i]?.key?.name;
    if (
      this.multipleFields[i]?.value != null &&
      this.multipleFields[i]?.value != ''
    ) {
      this.dataTable.filters[name]['value'] = null;
    }
    this.multipleFields.splice(i, 1);
    this.search(this.multipleFields[i - 1].value, i - 1, this.multipleFields);
  }
  // got to top when form submitted
  goToTop() {
    this.scroller.scrollToAnchor('backToTop');
  }
  onPaymentMethodeSelect() {
    this.chequeDateState = false;
    this.verificationForm.patchValue({
      cheque_date: null,
    });
    if (this.verificationForm.value.paid_by == 'Cheque') {
      this.chequeMinDate = this.minDate;
      this.chequeMaxDate = this.maxDate;
    } else {
      this.chequeMinDate = null;
      this.chequeMaxDate = this.today;
    }
  }

  deleteConfirmation(regularMemberList: any) {
    // if(regularMemberList.is_delete){
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: (res: any) => {
        this.apiservice.deleteRegularMemberByComittee(regularMemberList.id).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });

            this.getRegularMemberListFunction();
          }
          else {
            this.messageService.add({
              severity: 'error',
              detail: res.message,
            });
          }
        })
      },
    });
    // }
  }
  // **************************************************Assign User Permissions******************************************************
  getMemberRole() {
    this.RoleId = JSON.parse(
      localStorage?.getItem('access_token')
    )?.roleId;
    this.UserId = JSON.parse(
      localStorage?.getItem('access_token')
    )?.userId;
  }
  async getUserPermissions() {
    // if (this.RoleId && this.UserId) {
    //   this.spinner.show();
    //   await this.apiService.getUserPermission(this.RoleId, this.UserId).then((res: any) => {
    //     console.log(res);
    //     this.permission = res?.data?.filter(resp => resp?.permission?.title == "Regular Member");
    //     this.permission.map((data: any) => {
    //       console.log(data);
    //       if (data?.permission?.name == 'Delete') {
    //         this.enableDeleteButton = true;
    //       } else if (data?.permission?.name == 'Edit') {
    //         this.enableEditButton = true;
    //       } else if (data?.permission?.name == 'Approval') {
    //         this.enableApprovalButton = true;
    //       } else if (data?.permission?.name == 'List') {
    //         this.enableListButton = true;
    //       } else if (data?.permission?.name == 'View') {
    //         this.enableViewButton = true;
    //       } else if (data?.permission?.name == 'Verification') {
    //         this.enableVerificationButton = true;
    //       }
    //       console.log(this.permission);
    //     })
    //   })
    //   this.spinner.hide();
    // }



    let userPermissions: any;
    userPermissions = await this.utility.getUserRoles();
    this.userPermission = userPermissions.filter(res => res.permission.title == 'Regular Member');
    this.userPermission.map((res) => {
      if (res.permission.name == 'Edit') { this.enableEditButton = true }
      else if (res.permission.name == 'Create') { this.enableAddButton = true }
      else if (res.permission.name == 'Delete') { this.enableDeleteButton = true }
      else if (res.permission.name == 'View') { this.enableViewButton = true }
      else if (res.permission.name == 'Approval') { this.enableApprovalButton = true }
      else if (res.permission.name == 'Verification') { this.enableVerificationButton = true }
    })
  }
  routerToRegularember(regularListId: any) {
    if (this.localStorage.role == 'Nominee 1' || this.localStorage.role == 'Nominee 2') {
      this.router.navigateByUrl('/user-panel/edit-profile/regular-member');
    }
    else if (this.localStorage.role == 'Admin' || this.localStorage.role == 'UCCI Staff') {
      this.router.navigateByUrl('/user-panel/edit-profile/regular-member/' + regularListId);
    }
  }
  showTable(tableType: string) {
    if (tableType == 'listing') {
      this.showListing = true;
      this.showRenewalListing = false;
    } else if (tableType == 'renewal_listing') {
      this.showListing = false;
      this.showRenewalListing = true;
    }
  }
  // get renewal listing
  getRenewalList() {
    if (localStorage.getItem('member_id')) {
      let memberId: any = JSON.parse(localStorage.getItem('member_id'))
      this.apiService.memberRenewalList(memberId).then((res: any) => {
        this.renewalListing = res?.data;
      })
    }
  }
  noDupli(item: string): string {
    const valuesArray = item.split(',').map(value => value.trim());
    const uniqueValuesArray = Array.from(new Set(valuesArray));
    return uniqueValuesArray.join(', ');
}

}
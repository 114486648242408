<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<div *ngIf="route.url.includes('/event-details')">
    <!-- <app-header></app-header> -->
    <!-- Start Page Title Area -->
    <div class="page-title-area" *ngFor="let Content of pageTitleContent;"
        style="background-image: url({{Content.backgroundImage}});">
        <div class="container">
            <div class="page-title-content">
                <h2>{{Content.title}}</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{Content.title}}</li>
                </ul>
            </div>

        </div>
    </div>
    <!-- End Page Title Area -->

    <!-- Start Blog Details Area -->
    <section class="blog-details-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image text-center">
                            <img src="{{eventData.coverage_image}}" alt="image">
                        </div>

                        <div class="article-content">
                            <div class="entry-meta">
                                <ul class="d-flex justify-content-center">
                                    <li>
                                        <i class='bx bx-pin'></i>
                                        <span>Venue</span>
                                        <a routerLink="/event-grid">{{eventData?.fee_name ?? eventData?.facility_name}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-time'></i>
                                        <span>Time</span>
                                        {{eventData.date | date : 'HH:mm'}}
                                    </li>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        <span>Date</span>
                                        {{eventData.date | date : 'dd/MM/yyyy'}}
                                    </li>
                                </ul>
                                <share-buttons theme="circles-dark"
                                    [include]="['facebook', 'email','linkedin','telegram', 'twitter', 'whatsapp', 'copy']"
                                    [showIcon]="true" [showText]="false"
                                    [url]="frontURL+'/event-details/'+slug?.params?.slug"
                                    description="Angular Share Buttons" twitterAccount="rodrigokamada" style="text-align: center;
                                    padding: 20px 0;">
                                </share-buttons>
                            </div>
                            <h3>{{eventData.event_name}}</h3>
                            <!-- <p>{{eventData.description}}</p> -->
                            <p [innerHTML]="eventData.description"></p>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde laudantium veniam
                                architecto a ratione ea, beatae nam saepe. Vitae impedit repellendus placeat quod.
                                Repellat, odio illo. Repudiandae laudantium et assumenda? Lorem ipsum dolor sit amet
                                consectetur adipisicing elit. Tempora ut dignissimos alias impedit debitis quasi eos
                                harum reprehenderit voluptatem dolorum. Nam, quaerat! Eveniet impedit qui veniam in,
                                quae voluptatibus ut.</p> -->
                        </div>
                        <!-- <div class="custom_form" *ngIf="localStorage"> -->
                        <div class="custom_form" *ngIf="eventDateCheck">
                            <div class="form_heading">
                                <h6>
                                    Event Registration Form
                                </h6>
                            </div>
                            <ng-container *ngIf="eventDateCheck">
                                <ng-container *ngIf="localStorage">
                                    <ng-container *ngIf="customFromData.length>0">
                                        <lib-renderer [sections]="customFromData"
                                            (sectionsData)="getFormResponse($event)">
                                        </lib-renderer>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="['Admin', 'UCCI Staff', 'CEO', 'President'].includes(localStorage?.role)">
                                        <ng-container *ngIf="customFromData.length>0">
                                            <lib-renderer [sections]="customFromData" (sectionsData)="getFormResponse($event)">
                                            </lib-renderer>
                                        </ng-container>
                                    </ng-container> -->
                                    <!-- <ng-container *ngIf="!['Admin', 'UCCI Staff', 'CEO', 'President'].includes(localStorage?.role)">
                                        <ng-container *ngIf="bookedEventString">
                                            <div class="alert_section my-5">
                                                <p class="my-3">{{bookedEventString}}</p>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!bookedEventString">
                                            <ng-container *ngIf="customFromData.length>0">
                                                <lib-renderer [sections]="customFromData" (sectionsData)="getFormResponse($event)">
                                                </lib-renderer>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container> -->
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="eventDateCheck">
                            <div class="alert_section my-5" *ngIf="!localStorage">
                                <p class="my-3">Please Login/Register First in Order to Complete Your Event
                                    Registration.
                                    <br> <span (click)="openModel()"
                                        style="cursor: pointer;text-decoration : underline;">Click Here to
                                        Login/Register</span>
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!eventDateCheck">
                            <div class="alert_section my-5" >
                                <p class="my-3">
                                    Registration closed
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12 past_events">
                    <aside class="widget-area">

                        <section class="widget widget_vesax_posts_thumb">
                            <h3 class="widget-title">Past Events</h3>
                            <div class="past-events">
                                <ng-container *ngFor="let event of pastEvents; index as index">
                                    <article class="item" *ngIf="index < pastEventLength['length']">
                                        <span>
                                            <a class="thumb">
                                                <img class="fullimage cover bg1"
                                                    [src]="event?.coverage_image" alt="">
                                            </a>
                                            <div class="info">
                                                <span>{{event?.event?.date | date : 'dd/MM/yyyy'}}</span>
                                                <h4 class="title usmall"><a style="cursor: pointer;"
                                                        (click)="showDetails(event?.id)">{{event?.event_name}}</a>
                                                </h4>
                                            </div>
                                        </span>
                                    </article>
                                </ng-container>
                            </div>
                            <span *ngIf="pastEvents['length'] > 3">
                                <a (click)="showAllData('past')" class="show_btn">{{pastEventLength.button}}</a>
                            </span>
                            <span class="no-data" *ngIf="pastEvents.length == 0">
                                No Past Events
                            </span>
                        </section>
                    </aside>
                </div>
            </div>
        </div>
    </section>
    <!-- End Blog Details Area -->
    <!-- <app-footer-style-one></app-footer-style-one> -->
    <!-- <app-copyrights></app-copyrights> -->
    <app-footer></app-footer>
</div>




<!--============================================ dashboard event ====================================================-->
<div *ngIf="route.url.includes('event-dashboard-details')">
    <!-- <app-dashboard-sidemenu></app-dashboard-sidemenu>
    <div class="main-content d-flex flex-column"> -->
    <!-- Start Page Title Area -->
    <div class="page-title-area" *ngFor="let Content of pageTitleContent;"
        style="background-image: url({{Content.backgroundImage}});">
        <div class="container">
            <div class="page-title-content">
                <h2>{{Content.title}}</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{Content.title}}</li>
                </ul>
            </div>
        </div>
    </div>
    <!-- End Page Title Area -->

    <!-- Start Blog Details Area -->
    <section class="blog-details-area bg-f9f9f9 ptb-100" *ngIf="EventDetails">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image dashboard">
                            <img src="{{eventData.coverage_image}}" alt="image">
                        </div>

                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-pin'></i>
                                        <span>Venue</span>
                                        <!-- {{eventData | json}} -->
                                        <a routerLink="/event-grid">{{eventData?.fee_name  ?? eventData?.facility_name}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-time'></i>
                                        <span>Time</span>
                                        {{eventData.date | date : 'HH:mm'}}
                                    </li>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        <span>Date</span>
                                        {{eventData.date | date : 'dd/MM/yyyy'}}
                                    </li>
                                </ul>
                                <share-buttons theme="circles-dark"
                                    [include]="['facebook', 'email','linkedin','telegram', 'twitter', 'whatsapp', 'copy']"
                                    [showIcon]="true" [showText]="false"
                                    [url]="frontURL+'/event-details/'+slug?.params?.slug"
                                    description="Angular Share Buttons" twitterAccount="rodrigokamada" style="text-align: center;
                                    padding: 20px 0;">
                                </share-buttons>
                            </div>
                            <h3>{{eventData.event_name}}</h3>
                            <!-- <p>{{eventData.description}}</p> -->
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde laudantium veniam
                                architecto a ratione ea, beatae nam saepe. Vitae impedit repellendus placeat quod.
                                Repellat, odio illo. Repudiandae laudantium et assumenda? Lorem ipsum dolor sit amet
                                consectetur adipisicing elit. Tempora ut dignissimos alias impedit debitis quasi eos
                                harum reprehenderit voluptatem dolorum. Nam, quaerat! Eveniet impedit qui veniam in,
                                quae voluptatibus ut.</p>
                        </div>

                        <div class="custom_form" *ngIf="localStorage">
                            <div class="form_heading">
                                <h6>
                                    Event Registration Form
                                </h6>
                            </div>
                            <ng-container *ngIf="eventDateCheck">
                                <ng-container *ngIf="localStorage">
                                    <ng-container *ngIf="customFromData.length>0">
                                        <lib-renderer [sections]="customFromData"
                                            (sectionsData)="getFormResponse($event)">
                                        </lib-renderer>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="eventDateCheck">
                            <div class="alert_section my-5" *ngIf="!localStorage">
                                <p class="my-3">Please Login/Register First in Order to Complete Your Event
                                    Registration.
                                    <br> <span (click)="openModel()"
                                        style="cursor: pointer;text-decoration : underline;">Click Here to
                                        Login/Register</span>
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!eventDateCheck">
                            <div class="alert_section my-5">
                                <p class="my-3">This is past event.
                                    <!-- <br><span>Click Here to Login/Register</span> -->
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <section class="widget widget_vesax_posts_thumb">
                            <h3 class="widget-title">Enrolled Events</h3>
                            <ng-container *ngFor="let event of futureEvents; index as index">
                                <article class="item" *ngIf="index < enrollEventLength['length']">
                                    <span>
                                        <a class="thumb">
                                            <img class="fullimage cover bg1"
                                                [src]="coverageURL+event?.event?.coverage_image" alt="">
                                        </a>
                                        <div class="info">
                                            <span>{{event?.event?.date | date : 'dd/MM/yyyy'}}</span>
                                            <h4 class="title usmall"><a>{{event?.event?.event_name}}</a></h4>
                                        </div>
                                    </span>
                                </article>
                            </ng-container>
                            <span *ngIf="futureEvents['length'] > 3">
                                <a (click)="showAllData('enroll')" class="show_btn">{{enrollEventLength.button}}</a>
                            </span>
                            <span class="no-data" *ngIf="futureEvents.length == 0">
                                No Enroll Events
                            </span>
                        </section>

                        <section class="widget widget_vesax_posts_thumb">
                            <h3 class="widget-title">Past Events</h3>
                            <ng-container *ngFor="let event of pastEvents; index as index">
                                <article class="item" *ngIf="index < pastEventLength['length']">
                                    <span>
                                        <a class="thumb">
                                            <img class="fullimage cover bg1"
                                                [src]="coverageURL+event?.event?.coverage_image" alt="">
                                        </a>
                                        <div class="info">
                                            <span>{{event?.event?.date | date : 'dd/MM/yyyy'}}</span>
                                            <h4 class="title usmall"><a style="cursor: pointer;"
                                                    (click)="showDetails(event?.id)">{{event?.event?.event_name}}</a>
                                            </h4>
                                        </div>
                                    </span>
                                </article>
                            </ng-container>
                            <span *ngIf="pastEvents['length'] > 3">
                                <a (click)="showAllData('past')" class="show_btn">{{pastEventLength.button}}</a>
                            </span>
                            <span class="no-data" *ngIf="pastEvents.length == 0">
                                No Past Events
                            </span>
                        </section>
                    </aside>
                </div>
            </div>
        </div>
    </section>

    <!-- **********************************************Past Event Details*************************************************************** -->
    <section class="blog-details-area bg-f9f9f9 ptb-100" *ngIf="pastEventDetails">
        <div class="container">
            <div class="row">
                <ng-container>
                    <div class="col-md-12">
                        <ng-container *ngFor="let eventData of details">
                            <div class="blog-details-desc">
                                <div class="article-image dashboard">
                                    <img [src]="coverageURL+eventData?.event.coverage_image" alt="image">
                                </div>

                                <div class="article-content">
                                    <div class="entry-meta">
                                        <ul>
                                            <li>
                                                <i class='bx bx-pin'></i>
                                                <span>Venue</span>
                                                <a routerLink="/event-grid">{{eventData?.fee_name  ?? eventData?.facility_name}}</a>
                                            </li>
                                            <li>
                                                <i class='bx bx-time'></i>
                                                <span>Time</span>
                                                {{eventData.event.date | date : 'HH:mm'}}
                                            </li>
                                            <li>
                                                <i class='bx bx-calendar'></i>
                                                <span>Date</span>
                                                {{eventData.event.date | date : 'dd/MM/yyyy'}}
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>{{eventData?.event?.event_name}}</h3>
                                    <!-- <p>{{eventData.description}}</p> -->
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde laudantium
                                        veniam
                                        architecto a ratione ea, beatae nam saepe. Vitae impedit repellendus placeat
                                        quod.
                                        Repellat, odio illo. Repudiandae laudantium et assumenda? Lorem ipsum dolor
                                        sit amet
                                        consectetur adipisicing elit. Tempora ut dignissimos alias impedit debitis
                                        quasi eos
                                        harum reprehenderit voluptatem dolorum. Nam, quaerat! Eveniet impedit qui
                                        veniam in,
                                        quae voluptatibus ut.</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
    <!-- End Blog Details Area -->
</div>
<!-- </div> -->

<!-- <app-footer></app-footer> -->

<ng-container *ngIf="paymentStatus">
    <app-payment-form [RecivedData]="paymentResponse"></app-payment-form>
</ng-container>
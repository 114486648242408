<p-dialog visible="true" [closable]="false" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [style]="{width: '50vw'}" modal="true">
    <ng-template pTemplate="header">
        <h3>
            Access Alert
        </h3>
    </ng-template>
    <section class="authorization_section">
        <div class="container">
            <div class="modal_text text-center">
                <div class="icon_class">
                    <i class="pi pi-exclamation-circle"></i>
                </div>
                <span *ngIf="this.route.url != '/user-panel/certificate-of-origin-form'">
                    Regular Membership registration is currently closed.
                    Please check back again soon for further updates!</span>
                <span *ngIf="this.route.url == '/user-panel/certificate-of-origin-form'">
                    Certificate of Origin form is closed right now! Please click <a
                        style="color: var(--mainColor); font-weight: 800; cursor: pointer;"
                        href="https://www.trade.gov.in/">here</a> to visit DGFT portal to complete your
                    request.</span>
            </div>
        </div>
    </section>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="routeToDashboard()" label="Ok" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>
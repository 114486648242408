import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class utility {

  constructor(private messageService: MessageService, private apiservice: ApiService) { }

  path: any;
  fileReader(event: any): Promise<any> {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event: any) => {
      this.path = event.target.result;
    };
    return this.path.toPromise();
  }

  htmlInjection(value) {
    let changed_value = value
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/\r\n|\r|\n/g);

    return changed_value;
  }

  fileTypeCheck(type: any, event: any) {
    if (event.target.files && event.target.files[0]) {
      if (type == 'image') {
        if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {
          if (event.target.files[0].size < 2000000) {
            return true;
          }
          else {
            this.messageService.add({
              severity: 'error',
              detail: 'Please Upload Max 2MB File!',
            });
            return false;
          }
        }
        else {
          this.messageService.add({
            severity: 'error',
            detail: 'Please Upload Image File!',
          });
          return false;
        }
      }
      else if (type == 'pdf') {
        if (event.target.files[0].type === 'application/pdf') {
          if (event.target.files[0].size < 10000000) {
            return true;
          }
          else {
            this.messageService.add({
              severity: 'error',
              detail: 'Please Upload Max 10MB File!',
            });
            return false;
          }
        }
        else {
          this.messageService.add({
            severity: 'error',
            detail: 'Please Upload PDF File!',
          });
          return false;
        }
      }
    }
  }

 getFilteredByName(dataTable: any) {
    let filteredBy = null;
    let filterValue = null;

    if (dataTable.filteredValue != null) {
      for (const key in dataTable.filters) {
        if (dataTable.filters.hasOwnProperty(key)) {
          const filter = dataTable.filters[key];
          if (filter && filter.value !== null && filter.value !== '') {
            filteredBy = key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
            filterValue = filter.value;
            break;
          }
        }
      }
    }
    let filtername = filteredBy+':'+ filterValue
    return filtername;
}





  async getUserRoles() {
    let user = this.apiservice.localStorageObject();
    let userRoleData = [];
    await this.apiservice.getUserPermission(user.userId, user.roleId).then((res: any) => {
      userRoleData = res.data;
    })

    return userRoleData;
  }
  restrictSpecialChar(event: KeyboardEvent): void {
    const allowedCharacters = /[0-9a-zA-Z. ]/;
    const inputChar = event.key;

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault();
    }
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { ConstantPool } from '@angular/compiler';
import { SHA256 } from 'crypto-js';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
// import { Validators } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { utility } from 'src/app/services/utility';
import { WebsiteService } from 'src/app/services/website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild('formlogin') formlogin: NgForm;
  @ViewChild('formRegister') formRegister: NgForm;
  @ViewChild('forgotSubmit') forgotSubmit: NgForm;
  @ViewChild('login') login: ElementRef;

  @Input() modalCheck: any;
  // @Output() getValue = new EventEmitter;
  @Output() getValue = new EventEmitter<string>();

  openMenu: boolean = false;

  openMobileMenu: boolean = false;

  marketPlaceSubMenu: any = false;

  child1Check: any = false;

  child1Index: any;

  child2Index: any;

  child2Check: any = false;

  child3Index: any;

  child3Check: any = false;

  child4Index: any;

  child4Check: any = false;

  child5Index: any;

  resSignupMsg: string = '';

  resSignupMsgCheck: string = ' ';

  resSigninMsgCheck: string = ' ';

  emailAddress: string = '';

  resSigninMsg: string = ' ';

  userName: string = '';

  localStorage: any;

  child5Check: any = false;

  Modal: boolean = false;
 showAaoFactoryPage:boolean=false
  UserEmail: any;

  disablebtn: boolean = false;

  roles: any = [];

  submitted = false;

  resData: any = {};

  classCheck: string = '';

  signUpSubmitted = false;

  spinnerMsg: string;

  headerText: string = '';

  menuIndex: any;

  companyName: any;

  authMenus: boolean = false;

  adminMenus: boolean = false;

  ceoMenus: boolean = false;

  staffMenus: boolean = false;

  hwmMenus: boolean = false;

  nonMemberMenus: boolean = false;

  regularMenus: boolean = false;

  guestMenus: boolean = false;

  memberRole: any;

  childMenus: any = [];

  loginobj: any;

  changepassword: boolean = false;

  forgotButton: string = 'Send OTP';

  showOtp: boolean = false;

  show = false;

  isHomePage: boolean = true;

  isRegister: boolean = false;
  isLogin:boolean = true;
  isLogin1:boolean = true;
  isRegister2: boolean = false;

  loginForm = new FormGroup({
    loginemail: new FormControl('', [Validators.required]),
    loginpassword: new FormControl('', [Validators.required]),
  });

  roleForm = new FormGroup({
    memberrole: new FormControl(null, [Validators.required]),
  });

  registerForm = new FormGroup({
    signupname: new FormControl(null, [Validators.required]),
    signupemail: new FormControl(null, [Validators.required]),
    signuppassword: new FormControl(null, [Validators.required]),
    confirmpassword: new FormControl(null, [Validators.required]),
  });

  forgotForm = this.fb.group({
    emailAddress: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.pattern(/\d/)]),
    password: new FormControl(''),
    confirmpassword: new FormControl(''),
  });

  PasswordForm = new FormGroup({
    newpassword: new FormControl('', [Validators.required]),
    confirmpassword: new FormControl('', [Validators.required]),
    passemail: new FormControl(''),
    otp: new FormControl(null, Validators.pattern(/\d/)),
  });
  clickCheck: boolean = false;
  userPanelAuth: boolean = false;
  showpass: boolean = false;
  showconfirmpass: boolean = false;
  constructor(
    private websiteService: WebsiteService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private apiservice: ApiService,
    private messageService: MessageService,
    private commonFunction: CommonClass,
    private fb: FormBuilder,
    public sanit: DomSanitizer,
    private utility: utility
  ) {
    // this.route.events.subscribe((e: RouterEvent) => {
    //   this.navigationInterceptor(e);
    // });
  }

  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     console.log(event.url);
  //     if (event.url == '/user-panel') {
  //       this.spinner.show();
  //     }
  //     if (event instanceof NavigationEnd) {
  //       this.spinner.hide();
  //     }
  //   }
  // }

  navLinks: any = [];

  img_baseURL = environment.ucci_base_url;

  ngOnChanges() { }

  async ngOnInit() {

    this.localStorage = this.commonFunction.getLocalStorage();

    this.websiteService.navigateOption.subscribe((res: any) => {
      // debugger;
      // console.log(res == 'website');
      if (localStorage.getItem('access_token')) {
        if (res == 'website') {
          this.headerText = 'Go to Dashboard';
        } else if (res == 'dashboard') {
          this.headerText = 'Go to ucciudaipur.com';
        } else if (res == 'logout') {
          this.headerText = '';
          localStorage.removeItem('access_token');
        }
      } else {
        this.headerText = '';
        localStorage.removeItem('access_token');
      }
      // console.log(this.headerText);
      // console.log(this.localStorage);
    });

    this.websiteService.modalCheck.subscribe((res: any) => {
      // console.log(res);
      if (res == true) {
        this.login.nativeElement.click();
      }
    });

    var access_token: any = localStorage.getItem('access_token');
    if (access_token && access_token != 'undefined') {
      this.userName = JSON.parse(localStorage.getItem('access_token')).username;
      this.UserEmail = JSON.parse(
        localStorage.getItem('access_token')
      ).useremail;
    }

    if (this.localStorage) {
      // debugger;
      if (
        !this.localStorage?.role &&
        this.localStorage?.memberRole.length > 1
      ) {
        this.roles = this.localStorage?.memberRole;
        this.Modal = true;
      }
      this.authMenus = true;
      if (this.localStorage?.role == 'Admin') {
        this.adminMenus = true;
        this.ceoMenus = false;
        this.hwmMenus = false;
        this.regularMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
        this.staffMenus = false;
      }
      else if (this.localStorage?.role == 'CEO') {
        this.ceoMenus = true;
        this.staffMenus = false;
        this.adminMenus = false;
        this.hwmMenus = false;
        this.regularMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
      }
      else if (this.localStorage?.role == 'UCCI Staff') {
        this.staffMenus = true;
        this.ceoMenus = false;
        this.adminMenus = false;
        this.hwmMenus = false;
        this.regularMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
      }
      else if (this.localStorage?.role == environment.roles.hwmOwner || this.localStorage?.role == environment.roles.hwmContact) {
        this.hwmMenus = true;
        this.staffMenus = false;
        this.ceoMenus = false;
        this.adminMenus = false;
        this.regularMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
      }
      else if (this.localStorage?.role == environment.roles.nonMember) {
        this.nonMemberMenus = true;
        this.hwmMenus = false;
        this.staffMenus = false;
        this.ceoMenus = false;
        this.adminMenus = false;
        this.regularMenus = false;
        this.guestMenus = false;
      }
      else if (this.localStorage?.role == environment.roles.nominee1 || this.localStorage?.role == environment.roles.nominee2) {
        this.regularMenus = true;
        this.hwmMenus = false;
        this.staffMenus = false;
        this.ceoMenus = false;
        this.adminMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
      }
      else if (this.localStorage?.role == 'Guest') {
        this.guestMenus = true;
        this.hwmMenus = false;
        this.staffMenus = false;
        this.ceoMenus = false;
        this.adminMenus = false;
        this.regularMenus = false;
        this.nonMemberMenus = false;
      }
    }
    else {
      this.authMenus = false;
      this.adminMenus = false;
      this.ceoMenus = false;
      this.hwmMenus = false;
      this.regularMenus = false;
      this.staffMenus = false;
      this.nonMemberMenus = false;
      this.guestMenus = false;
    }
    this.getMemberRole();
    this.getUserEmail();
    // this.checkFeedbackValidation();

    // this.spinner.show();
    // debugger;
    let dataCheck: boolean = false;
    this.websiteService.menu.subscribe(async (res: any) => {
      // console.log('hello', res);
      if (res?.length > 0) {
        this.navLinks = res;
      } else {
        await this.websiteService.getMenus().then((res: any) => {
          // console.log('hello', res);
          this.navLinks = res?.data;
          dataCheck = true;
        });
      }
    });
    setTimeout(() => {
      if (dataCheck) {
        this.websiteService.menu.next(this.navLinks);
      }
    }, 2000);

    this.apiservice.authModalState.subscribe((res: any) => {
      if (res) {
        this.authMenus = false;
        this.adminMenus = false;
        this.ceoMenus = false;
        this.hwmMenus = false;
        this.regularMenus = false;
        this.staffMenus = false;
        this.nonMemberMenus = false;
        this.guestMenus = false;
      }
    })
    this.getData();
    this.spinner.hide();
  }

  getMemberRole() {
    this.memberRole = JSON.parse(
      localStorage?.getItem('access_token')
    )?.memberRole;
  }

  getUserEmail() {
    let Storage = localStorage.getItem('access_token');
    if (Storage) {
      this.UserEmail = JSON.parse(Storage).useremail;

      this.PasswordForm.patchValue({
        passemail: this.UserEmail,
      });
    }
  }

  openmenu(index?: any) {
    // debugger;
    if (this.openMenu) {
      this.clickCheck = false;
    } else {
      this.clickCheck = true;
    }
    // console.log(index);
    this.menuIndex = index;
    if (index || index == 0) {
      this.childMenus = this.navLinks[index]?.sub_menu;
      console.log(this.childMenus)
      this.openMenu = !this.openMenu;
      // console.log(this.childMenus);
    } else {
      this.openMobileMenu = !this.openMobileMenu;
    }
    this.child1Check = false;
    this.child2Check = false;
    this.child3Check = false;
    this.child4Check = false;
    this.child5Check = false;
  }

  openChildMenus(string?: any, index?: any) {
    this.clickCheck = true;
    if (string == 'child1') {
      // console.log(this.child1Check, index);
      this.child1Index = index;
      this.child1Check = !this.child1Check;
    } else if (string == 'child2') {
      this.child2Index = index;
      this.child2Check = !this.child2Check;
    } else if (string == 'child3') {
      this.child3Index = index;
      this.child3Check = !this.child3Check;
    } else if (string == 'child4') {
      this.child4Index = index;
      this.child4Check = !this.child4Check;
    } else if (string == 'child5') {
      this.child5Index = index;
      this.child5Check = !this.child5Check;
    } else if (string == 'user_panel') {
      this.userPanelAuth = !this.userPanelAuth;
    }
  }

  navigate(
    slug1: any,
    slug2?: any,
    slug3?: any,
    slug4?: any,
    slug5?: any,
    slug6?: any
  ) {
    let slug: any = '';
    console.log(slug);

    if (slug1) {
      slug = slug1;
    }
    if (slug2) {
      slug = slug + '/' + slug2;
    }
    if (slug3) {
      slug = slug + '/' + slug3;
    }
    if (slug4) {
      slug = slug + '/' + slug4;
    }
    if (slug5) {
      slug = slug + '/' + slug5;
    }
    if (slug6) {
      slug = slug + '/' + slug6;
    }
    console.log(slug);
    if (slug && !(slug.includes('string'))) {
      if (slug == 'Home') {
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate(['/']);
      }
      else if (slug?.includes('apply-for-awards')) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var anchor = document.createElement('a');
        // anchor.download = pdf_url;
        anchor.href = environment.award_URL;
        anchor.target = '_blank';
        anchor.click();
      }
      else if (slug?.includes('Sub-Committees')) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        var anchor = document.createElement('a');
        // anchor.download = pdf_url;
        anchor.href = environment.ucci_base_url + '/sub_committee.pdf';
        anchor.target = '_blank';
        anchor.click();
      }
      else if (slug != 'Marketplace') {
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate([slug]);
      }



      else {
        // if (this.localStorage) {
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate(['marketplace-products']);
        // }
        // this.login.nativeElement.click();
      }
    }

    this.openMenu = false;
    this.child1Check = false;
    this.child2Check = false;
    this.child3Check = false;
    this.child4Check = false;
    this.child5Check = false;
  }

  resetForm() {
    this.classCheck = 'login';
    this.showOtp = false;
    this.changepassword = false;
    this.forgotButton = 'Send OTP';
    this.formlogin.resetForm();
    this.formRegister.resetForm();
    this.forgotSubmit.resetForm();
    this.forgotForm.setControl('otp', this.fb.control(''));
    this.forgotForm.reset();
    this.modalCheck = false;
    // this.getValue.emit('false');
    // this.getValue.emit('ello');
    // alert();
  }

  onLoginClick(string:any) {
    // this.loginForm.reset();
    // this.resetForm();
    if(string == 'login'){
      this.classCheck = 'login'
      this.isLogin = true;
      this.isRegister = false
    }else if(string == 'register'){
      this.classCheck = 'register'
      this.isLogin = false;
    this.isRegister = true
    }
    this.formlogin.resetForm();
    console.log('====================================');
    console.log(this.loginForm.valid);
    console.log('====================================');
  }

  loginIn() {
    this.submitted = true;

    if (this.loginForm.valid) {
      this.spinnerMsg = 'Logging In';
      this.spinner.show();
      let data = Object.assign({}, this.loginForm.value);
      // const password = data.loginpassword;
      // const encryptedPassword = SHA256(password).toString();
      this.loginobj = {
        email: data.loginemail,
        password: data.loginpassword,
      };
      this.apiservice.login(this.loginobj).subscribe(
        (res: any) => {
          this.resData = res;
          this.roles = res.member_role;

          if (res.code == 200) {
            this.saveAccessToken(this.resData, null);
            // this.messageService.add({
            //   severity: 'success',
            //   detail: 'User logged in successfully!',
            // });
            document.getElementById('close_btn').click();

            // setTimeout(() => {
            if (this.roles.length > 1) {
              this.Modal = true;
            }
            this.sendRole();
            this.localStorage = this.commonFunction.getLocalStorage();
            localStorage.setItem('memberShipId', this.roles[0]?.membership_no)
            // if (localStorage.getItem('redirect_url')) {
            //   this.route.navigateByUrl(localStorage.getItem('redirect_url'));
            // }
            // else {
            // if (this.localStorage.role == 'Admin') {
            //   setTimeout(() => {
            // this.route.navigateByUrl('/user-panel/dashboard');
            //   }, 1500);
            // }
            // }
            // }, 1200);
            if(res.role[0] === 'AM'){
              console.log('in')
              localStorage.setItem('isAwardee', 'AM');
            }
            if(res.member_role){
              localStorage.setItem('membershipNo', res?.member_role[0]?.membership_no);
            }
           
          }
        },
        (error: HttpErrorResponse) => {
          // console.log(error);
          this.disablebtn = true;

          setTimeout(() => {
            this.disablebtn = false;
          }, 3000);
        }
      );
    }
  }

  signUp() {
    this.signUpSubmitted = true;
    let formData = new FormData();

    if (this.registerForm.valid) {
      this.spinnerMsg = 'Signing up';
      this.spinner.show();
      let data = this.registerForm.value;
      formData.append('name', this.utility.htmlInjection(data.signupname));
      formData.append('email', data.signupemail);
      formData.append('password', data.signuppassword);
      formData.append('password_confirmation', data.confirmpassword);
      this.apiservice.register(formData).then(
        (res) => {
          this.resSignupMsgCheck = 'success';
          this.resSignupMsg = res.message;
          this.saveAccessTOkenforSignup(res);
          let roleObject = {
            member_role: res.member_role[0].value,
          };
          this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
            localStorage.setitem('data', JSON.stringify(res));
          });
          document.getElementById('close_btn').click();
          document.getElementById('close_btn2').click();
          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            detail: 'User Created Successfully!',
          });
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  get f() {
    return this.registerForm.controls;
  }
  // convenience getter for easy access to form fields for login form
  get g() {
    return this.loginForm.controls;
  }
  // convenience getter for easy access to form fields for change password form
  get p() {
    return this.PasswordForm.controls;
  }
  // convenience getter for easy access to form fields for change password form
  get o() {
    return this.roleForm.controls;
  }

  onChange(value) {
    // this.bindEvents();
    let changed_value = value;
    // .replace(/(<([^>]+)>)/gi, '')
    // .replace(/\r\n|\r|\n/g);
    // console.log(
    //   `form value : ${this.registerForm.value.signupname}, input value : ${value}, Changed Value : ${changed_value}`
    // );
  }

  forgotGetOtp(forgotSubmit: FormGroupDirective) {
    if (this.forgotForm.valid) {
      let data = {
        email: this.forgotForm.value.emailAddress,
      };
      if (this.forgotButton == 'Send OTP') {
        this.apiservice.forgotPassword(data).then((res: any) => {
          this.spinner.show();
          if (res.code == 200) {
            this.forgotForm.setControl(
              'otp',
              this.fb.control('', Validators.required)
            );
            this.showOtp = true;
            this.emailAddress = this.forgotForm.get('emailAddress').value;
            this.forgotButton = 'Submit';
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          }
        });
      } else if (this.forgotButton == 'Submit') {
        this.loginForm.clearValidators();
        this.loginForm.updateValueAndValidity();
        this.registerForm.clearAsyncValidators();
        this.registerForm.updateValueAndValidity();
        this.spinner.show();
        let object = {
          email: this.emailAddress,
          otp: this.forgotForm.value.otp,
        };
        this.apiservice.OTPVerification(object).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.forgotButton = 'Change Password';
            this.spinner.hide();
            this.forgotForm.setControl(
              'password',
              this.fb.control('', [Validators.required])
            );
            this.forgotForm.setControl(
              'confirmpassword',
              this.fb.control('', [Validators.required])
            );

            this.changepassword = true;
          }
        });
      } else if (this.forgotButton == 'Change Password') {
        this.spinner.show();
        let object = {
          email: this.emailAddress,
          password: this.forgotForm.value.password,
          confirmpassword: this.forgotForm.value.confirmpassword,
        };
        this.apiservice.changePasswordApi(object).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          }
          this.spinner.hide();
          this.forgotForm.reset();
          Object.keys(this.forgotForm.controls).forEach((key: any) => {
            this.forgotForm.controls[key].setErrors(null);
          });
          forgotSubmit.resetForm();
          this.resetForm();
        });
      }
    } else {
      this.spinner.hide();
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill the Form',
      });
    }
  }

  saveAccessTOkenforSignup(res: any) {
    var localStorageData = {
      access_token: res?.access_token,
      username: res?.user?.name,
      useremail: res?.user?.email,
      role: res?.role[0],
      userId: res?.user?.id,
      roleId: res?.user?.role_id,
    };
    this.userName = res?.user?.name;
    this.UserEmail = res?.user?.email;
    localStorage.setItem('access_token', JSON.stringify(localStorageData));
    this.authMenus = true;
    let localstorage = JSON.parse(localStorage.getItem('access_token'))
    if (localstorage?.role == 'Admin') {
      this.adminMenus = true;
    }
    else if (localstorage?.role == 'CEO') {
      this.ceoMenus = true;
    }
    else if (localstorage?.role == 'UCCI Staff') {
      this.staffMenus = true;
    }
    else if (localstorage?.role == environment.roles.hwmOwner || localstorage?.role == environment.roles.hwmContact) {
      this.hwmMenus = true;
    }
    else if (localstorage?.role == environment.roles.nonMember) {
      this.nonMemberMenus = true;
    }
    else if (localstorage?.role == environment.roles.nominee1 || localstorage?.role == environment.roles.nominee2) {
      this.regularMenus = true;
    }
    else if (localstorage?.role == 'Guest') {
      this.guestMenus = true;
    }
    this.resetForm();
    this.registerForm.reset();
    this.route.navigateByUrl('/user-panel');
  }

  saveAccessToken(res: any, name?: string) {

    if (name == null) {
      var localStorageData = {
        access_token: res?.access_token,
        username: res?.user?.name,
        useremail: res?.user?.email,
        memberRole: res?.member_role,
        userId: res?.user?.id,
        roleId: res?.user?.role_id,
      };
      localStorage.setItem('access_token', JSON.stringify(localStorageData));
      this.authMenus = true;
      let localstorage = JSON.parse(localStorage.getItem('access_token'))
      if (localstorage?.role == 'Admin') {
        this.adminMenus = true;
      }
      else if (localstorage?.role == 'CEO') {
        this.ceoMenus = true;
      }
      else if (localstorage?.role == 'UCCI Staff') {
        this.staffMenus = true;
      }
      else if (localstorage?.role == environment.roles.hwmOwner || localstorage?.role == environment.roles.hwmContact) {
        this.hwmMenus = true;
      }
      else if (localstorage?.role == environment.roles.nonMember) {
        this.nonMemberMenus = true;
      }
      else if (localstorage?.role == environment.roles.nominee1 || localstorage?.role == environment.roles.nominee2) {
        this.regularMenus = true;
      }
      else if (localstorage?.role == 'Guest') {
        this.guestMenus = true;
      }
    } else if (name != null) {
      // this.spinner.show();
      var newLocalStorageData = {
        access_token: res?.access_token,
        username: res?.user?.name,
        useremail: res?.user?.email,
        role: name,
        memberRole: res?.member_role,
        userId: res?.user?.id,
        roleId: res?.user?.role_id,
      };
      localStorage.setItem('access_token', JSON.stringify(newLocalStorageData));
      localStorage.setItem('companyName', this.companyName);
      this.authMenus = true;
      let localstorage = JSON.parse(localStorage.getItem('access_token'))
      if (localstorage?.role == 'Admin') {
        this.adminMenus = true;
      }
      else if (localstorage?.role == 'CEO') {
        this.ceoMenus = true;
      }
      else if (localstorage?.role == 'UCCI Staff') {
        this.staffMenus = true;
      }
      else if (localstorage?.role == environment.roles.hwmOwner || localstorage?.role == environment.roles.hwmContact) {
        this.hwmMenus = true;
      }
      else if (localstorage?.role == environment.roles.nonMember) {
        this.nonMemberMenus = true;
      }
      else if (localstorage?.role == environment.roles.nominee1 || localstorage?.role == environment.roles.nominee2) {
        this.regularMenus = true;
      }
      else if (localstorage?.role == 'Guest') {
        this.guestMenus = true;
      }

      // this.route.events.subscribe((e: RouterEvent) => {
      //   this.navigationInterceptor(e);
      // });
      if (this.headerText == '') {
        this.route.navigateByUrl('/user-panel');
        setTimeout(() => {
          this.messageService.add({
            severity: 'success',
            detail: 'User logged in successfully!',
          });
        }, 1000);
      } else if (this.headerText) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      // this.spinner.hide();
      // setTimeout(() => {
      //   this.messageService.add({
      //     severity: 'success',
      //     detail: 'User logged in successfully!',
      //   });
      // }, 1000);
    }
    this.userName = res.user.name;
    this.getUserEmail();
  }
  @ViewChild('role') roleSelectionForm: NgForm;
  onRoleSelect(selectedRole: any) {
    console.log(selectedRole);
    localStorage.setItem('member_id', JSON.stringify(selectedRole.value.split('|')[0]))
    localStorage.setItem('memberShipId', selectedRole.membership_no)

    this.roleForm.patchValue({
      memberrole: selectedRole.value,
    });
    this.sendRole(this.roleSelectionForm);
  }
  sendRole(role?: NgForm) {
    console.log(this.roleForm.value, this.resData, this.roles);
    if (this.roles.length > 1) {
      if (this.roleForm.valid) {
        let roleObject = {
          member_role: this.roleForm.value.memberrole,
        };
        this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
          this.companyName = res?.member?.company_name;
          this.saveAccessToken(this.resData, res.role.name);
          this.Modal = false;
          this.roleSelectionForm.resetForm();
          this.roleForm.reset();
        });
        // this.route.navigateByUrl('/user-panel/dashboard');

        // setTimeout(() => {
        //   this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        //   this.route.onSameUrlNavigation = 'reload';
        //   this.route.navigate(['user-panel/dashboard']);
        // }, 1000);
      }
    }

    if (this.roles.length == 1) {
      let roleObject = {
        member_role: this.resData.member_role[0].value,
      };
      this.apiservice.sendDataOnLogin(roleObject).subscribe((res: any) => {
        this.companyName = res?.member?.company_name;
        this.saveAccessToken(this.resData, res.role.name);
        localStorage.setItem('member_id', JSON.stringify(this.roles[0]?.value.split('|')[0]))
        // localStorage.setItem('data', JSON.stringify(res));
      });
      // this.route.navigateByUrl('/user-panel/dashboard');
      // setTimeout(() => {
      //   this.route.routeReuseStrategy.shouldReuseRoute = () => false;
      //   this.route.onSameUrlNavigation = 'reload';
      //   this.route.navigate(['user-panel/dashboard']);
      // }, 1000);
    }
  }

  logout() {
    this.spinner.show();
    this.spinnerMsg = 'Loging Out';
    this.apiservice.logoutUser().subscribe(
      (res: any) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('data');
        localStorage.removeItem('Member_Role');
        localStorage.removeItem('companyName');
        localStorage.removeItem('variable');
        localStorage.removeItem('member_id');
        localStorage.removeItem('memberShipId');
        localStorage.clear();
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });
        this.userName = '';
        this.localStorage = this.commonFunction.getLocalStorage();
        this.spinner.hide();
        setTimeout(() => {
          this.route.navigateByUrl('/');
        }, 1500);
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
    this.websiteService.navigateOption.next('logout');
    this.authMenus = false;
    this.adminMenus = false;
    this.ceoMenus = false;
    this.staffMenus = false;
    this.hwmMenus = false;
    this.regularMenus = false;
    this.nonMemberMenus = false;
    this.guestMenus = false;
  }

  goToDashboard() {
    if (this.headerText == 'Go to Dashboard') {
      this.isHomePage = false;
      this.headerText = 'Go to ucciudaipur.com';
      this.route.navigateByUrl('user-panel/dashboard');
    } else if (this.headerText == 'Go to ucciudaipur.com') {
      this.isHomePage = true;
      this.headerText = 'Go to Dashboard';
      this.route.navigateByUrl('/');
    }
  }

  // ngOnDestroy() {
  // this.websiteService.menu.unsubscribe();
  // }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    // your click logic
    if (
      (this.clickCheck == true && this.openMenu == true) ||
      (this.clickCheck == true && this.openMobileMenu == true)
    ) {
      this.clickCheck = false;
    } else {
      this.openMenu = false;
      this.openMobileMenu = false;
    }
  }

  // Change Password
  submitButton: string = 'Change Password';
  showData: boolean = false;

  modelForChangepassword(string: any) {
    this.classCheck = string;
    this.submitButton = 'Change Password';
    this.showData = false;
    this.forgotSubmit.resetForm();
    this.PasswordForm.controls['otp'].removeValidators(Validators.required);
    this.PasswordForm.controls['otp'].updateValueAndValidity();
    this.forgotForm.reset();
  }
  getvalue(data, value: string) {
    console.log(data, value)
  }  // change password function
  changePassword() {
    Object.keys(this.PasswordForm.controls).forEach((res: any) => {
      console.log(res, this.PasswordForm.controls[res].status);
    });

    console.log(
      this.PasswordForm.valid,
      this.PasswordForm.value
    );
    if (this.submitButton == 'Change Password') {
      if (this.PasswordForm.valid && (this.PasswordForm.value.newpassword == this.PasswordForm.value.confirmpassword)) {
        // alert();
        let emailObject = {
          email: this.UserEmail,
        };
        this.apiservice.generateOTP(emailObject).subscribe((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
          if (res.code == 200) {
            this.showData = true;
            this.submitButton = 'Verify';
            this.PasswordForm.controls['otp'].setValidators([
              Validators.required,
            ]);
            this.PasswordForm.controls['otp'].updateValueAndValidity();
          }
        });
      }
    } else if (this.submitButton != 'Change Password') {
      if (this.PasswordForm.valid) {
        let otpObject = {
          otp: this.PasswordForm.value.otp,
          email: this.UserEmail,
        };
        this.spinner.show();
        this.apiservice.OTPVerification(otpObject).subscribe((res: any) => {
          this.spinner.hide();
          if (res.code == 200) {
            let data = new FormData();
            let Data = this.PasswordForm.value;
            data.append('email', this.UserEmail);
            data.append('password', Data.newpassword);
            this.apiservice.changePasswordApi(data).subscribe((res: any) => {
              this.messageService.add({
                severity: 'success',
                detail: res.message,
              });
              setTimeout(() => {
                document.getElementById('close_button').click();
                localStorage.removeItem('access_token');
                localStorage.removeItem('data');
                localStorage.removeItem('Member_Role');
                localStorage.removeItem('companyName');
                localStorage.removeItem('variable');
                localStorage.removeItem('member_id');
                localStorage.removeItem('memberShipId');
                this.route.navigateByUrl('/');
              }, 1500);
            });
          }
        });
      }
    }
    // this.passwordSubmitted = true;d
  }

  onSwitchAccount() {
    this.roles = this.localStorage?.memberRole;
    this.Modal = true;
  }

  showSubMenuAuth(string: any) {
    if (string == 'marketPlace') {
      this.marketPlaceSubMenu = !this.marketPlaceSubMenu;
    }
  }
  showPassword(type: any) {
    console.log(type)
    if (type == 'login') {
      this.show = !this.show;
    } else if (type == 'registerpass') {
      this.showpass = !this.showpass
    } else if (type == 'confirmpass') {
      this.showconfirmpass = !this.showconfirmpass
    }
  }



  // async getData() {
  //   this.spinner.show();
  //   try {
  //     const posts:any = await this.websiteService.getBlogPage();
  //     debugger
  //     // Check if any post has the category "Aao Factory Dekhein"
  //     const hasCategoryAaoFactoryDekhein = posts.some(post => post.category === "Aao Factory Dekhein");
  //     if (hasCategoryAaoFactoryDekhein) {
  //       debugger
  //       console.log('At least one post has the category "Aao Factory Dekhein".');
  //     } else {
  //       console.log('No post has the category "Aao Factory Dekhein".');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }


  // async getData() {
  //   await this.websiteService.getBlogPage().then((res: any) => {
  //     console.log(res);
  //     debugger
  //     const hasCategoryAaoFactoryDekhein =res.some(res => res.category === "Aao Factory Dekhein");
  //     if (hasCategoryAaoFactoryDekhein) {
  //       debugger
  //       console.log('At least one post has the category "Aao Factory Dekhein".');
  //     } else {
  //       console.log('No post has the category "Aao Factory Dekhein".');
  //     }
  //   })
  // }


  async getData() {
    await this.websiteService.getBlogPage().then((res: any) => {
      const data=res.data
      const itemsWithCategory = data.filter(template => template.category === 'Aao Factory Dekhein');   
         if (itemsWithCategory.length > 0) {
       this.showAaoFactoryPage=true
      } else {
        console.log('No item with category "Aao Factory Dekhein" found.');
        this.showAaoFactoryPage=false

      }
  });
}
isLoginFor(string:any){
  if(string == 'register'){
    this.classCheck = 'register'
    this.isLogin = false;
    this.isRegister = true
  }else if(string == 'login'){
    this.classCheck = 'login'
    this.isLogin = true;
    this.isRegister = false
  }
 
}
isLoginFor2(string:any){
  if(string == 'register'){
    this.classCheck = 'register'
    this.isLogin = false;
    this.isRegister = true
  }else if(string == 'login'){
    this.classCheck = 'login'
    this.isLogin = true;
    this.isRegister = false
  }
 
}
}
